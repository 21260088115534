import React, { useState } from 'react';
import { Modal, DropdownList, ButtonDropdownField } from '@forecast-it/design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import { SEAT } from '../../../constants';
const ChooseSeatModal = ({ closeModal, callback, collaboratorSeats, virtualSeats }) => {
    const intl = useIntl();
    const seatOptions = [];
    seatOptions.push({
        value: SEAT.CORE,
        label: intl.formatMessage({ id: 'settings_people.seat.core' }),
    });
    if (collaboratorSeats) {
        seatOptions.push({
            value: SEAT.COLLABORATOR,
            label: intl.formatMessage({ id: 'settings_people.seat.collaborator' }),
        });
    }
    if (virtualSeats) {
        seatOptions.push({
            value: SEAT.VIRTUAL,
            label: intl.formatMessage({ id: 'settings_people.seat.virtual' }),
        });
    }
    const [selectedSeatType, setSelectedSeatType] = useState();
    const [errorMessage, setErrorMessage] = useState('');
    const showErrorMessage = () => {
        setErrorMessage(intl.formatMessage({ id: 'settings_people.seat.please_select' }));
    };
    const chooseSeat = () => {
        if (selectedSeatType) {
            closeModal();
            callback(selectedSeatType.value);
        }
        else {
            showErrorMessage();
        }
    };
    return (React.createElement(Modal, { size: Modal.SIZE.SMALL, onCloseModal: closeModal },
        React.createElement(Modal.Title, null,
            React.createElement(FormattedMessage, { id: 'integrations.hibob.choose_seat' })),
        React.createElement(Modal.Content, null,
            React.createElement(DropdownList, null,
                React.createElement(DropdownList.Trigger, null,
                    React.createElement(ButtonDropdownField, { hasError: !!errorMessage, errorText: errorMessage }, selectedSeatType ? selectedSeatType.label : '-----')),
                React.createElement(DropdownList.Content, { useTriggerWidth: true }, seatOptions.map(option => (React.createElement(DropdownList.Item, { onClick: () => setSelectedSeatType(option) }, option.label)))))),
        React.createElement(Modal.Footer, null,
            React.createElement(Modal.PrimaryFooterButton, { onPress: chooseSeat, dataCy: 'modal-btn-choose-seat', disabled: false },
                React.createElement(FormattedMessage, { id: "common.next" })))));
};
export default ChooseSeatModal;
