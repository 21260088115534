import React, { useRef, useState } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { ComboBox, FlexColumn, FlexRow, Label, Text } from '@forecast-it/design-system';
import Styled from 'styled-components';
import MissionControlHeader from './MissionControlHeader';
import SageSyncAnalysisTable, { sageSyncAnalysisTableQuery } from './SageSyncAnalysisTable';
import ForecastQueryRenderer from '../../../ForecastQueryRenderer';
import { idFromGlobalId } from '../../../forecast-app/shared/util/GlobalIdUtil';
const PageWrapper = Styled.div `
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
`;
const InfoHeader = Styled.div `
  padding-left: 64px;
  padding-top: 16px;
`;
export const formatDate = (date) => {
    return date.toLocaleDateString('en-GB', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    });
};
function SageSyncAnalysisPage({ viewer }) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    const companySyncAnalysisHistory = (_b = (_a = viewer.missionControlData) === null || _a === void 0 ? void 0 : _a.companySyncAnalysisHistory) === null || _b === void 0 ? void 0 : _b.map(t => ({
        id: (t === null || t === void 0 ? void 0 : t.id) ? idFromGlobalId(t.id) : undefined,
        name: (t === null || t === void 0 ? void 0 : t.syncDate) ? formatDate(new Date(t.syncDate)) : undefined,
    }));
    const idString = ((_d = (_c = viewer.missionControlData) === null || _c === void 0 ? void 0 : _c.companySyncAnalysis) === null || _d === void 0 ? void 0 : _d.id)
        ? (_e = viewer.missionControlData) === null || _e === void 0 ? void 0 : _e.companySyncAnalysis.id
        : null;
    const companyName = (_g = (_f = viewer.missionControlData) === null || _f === void 0 ? void 0 : _f.companySyncAnalysis) === null || _g === void 0 ? void 0 : _g.companyName;
    const companyId = (_j = (_h = viewer.missionControlData) === null || _h === void 0 ? void 0 : _h.companySyncAnalysis) === null || _j === void 0 ? void 0 : _j.companyId;
    const [analysisId, setAnalysisId] = useState(idString ? idFromGlobalId(idString) : undefined);
    const scrollRef = useRef(null);
    return (React.createElement(PageWrapper, { ref: scrollRef },
        React.createElement(MissionControlHeader, { viewer: viewer }),
        React.createElement(InfoHeader, null,
            React.createElement(FlexColumn, null,
                React.createElement(FlexRow, null,
                    React.createElement(Label, null, "Company ID"),
                    React.createElement(Text, null, companyId)),
                React.createElement(FlexRow, null,
                    React.createElement(Label, null, "Company Name"),
                    React.createElement(Text, null, companyName)),
                React.createElement(FlexRow, null,
                    React.createElement(ComboBox, { value: analysisId, onChange: setAnalysisId, items: companySyncAnalysisHistory }, (item) => (React.createElement(ComboBox.Item, { textValue: item.name },
                        React.createElement(Text, null, item.name))))))),
        React.createElement(ForecastQueryRenderer, { isMc: true, key: "query-render-sageSyncAnalysisTable", query: sageSyncAnalysisTableQuery, variables: {
                companyId: companyId,
                analysisId: analysisId,
            }, render: relayProps => {
                return React.createElement(SageSyncAnalysisTable, Object.assign({}, relayProps, { scrollRef: scrollRef }));
            } })));
}
const sageSyncAnalysisQuery = graphql `
	query SageSyncAnalysisPage_Query($companyId: Int, $analysisId: Int, $forceRefresh: Boolean) {
		viewer {
			actualPersonId
			component(name: "sage_sync_analysis")
			...SageSyncAnalysisPage_viewer
				@arguments(companyId: $companyId, analysisId: $analysisId, forceRefresh: $forceRefresh)
		}
	}
`;
export { sageSyncAnalysisQuery };
export default createFragmentContainer(SageSyncAnalysisPage, {
    viewer: graphql `
		fragment SageSyncAnalysisPage_viewer on Viewer
		@argumentDefinitions(companyId: {type: "Int"}, analysisId: {type: "Int"}, forceRefresh: {type: "Boolean"}) {
			id
			...MissionControlHeader_viewer
			missionControlData {
				companySyncAnalysis(companyId: $companyId, analysisId: $analysisId, forceRefresh: $forceRefresh) {
					id
					companyId
					companyName
				}
				companySyncAnalysisHistory(companyId: $companyId) {
					id
					forecastCompanyId
					syncDate
				}
			}
		}
	`,
});
