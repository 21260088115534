/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type JiraIntegrationSection_project$ref = any;
type LabelDropdown_labels$ref = any;
type ProjectHeader_project$ref = any;
type ProjectHeader_psProject$ref = any;
type ProjectManualProgressConfiguration_project$ref = any;
type ProjectPriorityDropdown_company$ref = any;
type ProjectPriorityDropdown_project$ref = any;
type ProjectStatus_projectStatus$ref = any;
type RateCardDropdown_rateCards$ref = any;
type SecondaryNavigation_project$ref = any;
type ValueCalculations_project$ref = any;
export type BUDGET_TYPE = "FIXED_PRICE" | "FIXED_PRICE_V2" | "NON_BILLABLE" | "RETAINER" | "TIME_AND_MATERIALS" | "%future added value";
export type CANT_DELETE_PROJECT_REASON = "HAS_INVOICED_TIME" | "HAS_LOCKED_TIME" | "HAS_OTHER_PEOPLES_TIME_REGS" | "UNKNOWN" | "%future added value";
export type LANGUAGE = "DANISH" | "ENGLISH_EU" | "ENGLISH_UK" | "ENGLISH_US" | "FRENCH" | "POLISH" | "SPANISH" | "%future added value";
export type PERIODICITY = "DAILY" | "MONTHLY" | "WEEKLY" | "%future added value";
export type PERIOD_BUDGET_TYPE = "FIXED_HOURS" | "FIXED_PRICE" | "TIME_AND_MATERIALS" | "%future added value";
export type PROGRAM_BUDGET_TYPE = "CAPPED" | "FIXED_PRICE" | "UNCAPPED" | "%future added value";
export type PROJECT_STATUS = "DONE" | "HALTED" | "OPPORTUNITY" | "PLANNING" | "RUNNING" | "%future added value";
export type STATUS_CATEGORY = "DONE" | "INPROGRESS" | "TODO" | "%future added value";
export type TierType = "CORE" | "ENTERPRISE" | "FREE_FOREVER" | "LITE" | "PRO" | "TRIAL" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProjectSettingsPage_viewer$ref: FragmentReference;
declare export opaque type ProjectSettingsPage_viewer$fragmentType: ProjectSettingsPage_viewer$ref;
export type ProjectSettingsPage_viewer = {|
  +actualPersonId: ?string,
  +id: string,
  +component: ?string,
  +language: ?LANGUAGE,
  +githubConnected: ?boolean,
  +availableFeatureFlags: ?$ReadOnlyArray<?{|
    +key: ?string
  |}>,
  +company: ?{|
    +id: string,
    +createdAt: ?string,
    +tier: ?TierType,
    +forecastDemo: ?boolean,
    +modules: ?$ReadOnlyArray<?{|
      +moduleType: ?string
    |}>,
    +profiles: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
        |}
      |}>
    |},
    +sageIntacctTimeRegsFromForecast: ?boolean,
    +isChargebeeCustomer: ?boolean,
    +characterLimit: ?number,
    +salesforceEnabled: ?boolean,
    +newSalesforceEnabled: ?boolean,
    +hubspotAccountId: ?string,
    +sageIntacctEnabled: ?boolean,
    +salesforceUrl: ?string,
    +githubEnabled: ?boolean,
    +githubInstallationId: ?number,
    +vstsEnabled: ?boolean,
    +vstsAccount: ?$ReadOnlyArray<?string>,
    +unit4Enabled: ?boolean,
    +currency: ?string,
    +harvestEnabled: ?boolean,
    +slackEnabled: ?boolean,
    +newSlackEnabled: ?boolean,
    +jiraCloudEnabled: ?boolean,
    +jiraServerEnabled: ?boolean,
    +gitlabEnabled: ?boolean,
    +gitlabServerEnabled: ?boolean,
    +gitlabServerUrl: ?string,
    +teamsEnabled: ?boolean,
    +sageIntacctConnectionEnabled: ?boolean,
    +pipedriveCompanyDomain: ?string,
    +isUsingSchedulingPlanMode: ?boolean,
    +isUsingProjectAllocation: ?boolean,
    +isUsingMixedAllocation: ?boolean,
    +labels: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +$fragmentRefs: LabelDropdown_labels$ref
        |}
      |}>
    |},
    +clients: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
          +logoId: ?string,
          +logoDefaultId: ?number,
        |}
      |}>
    |},
    +rateCards: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +disabledRoles: ?$ReadOnlyArray<?{|
            +id: string
          |}>,
        |},
        +$fragmentRefs: RateCardDropdown_rateCards$ref,
      |}>
    |},
    +exchangeRates: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +currency: ?string,
          +rate: ?number,
        |}
      |}>
    |},
    +roles: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
        |}
      |}>
    |},
    +allPersons: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +firstName: ?string,
          +lastName: ?string,
          +fullName: ?string,
          +initials: ?string,
          +active: ?boolean,
          +profilePictureId: ?string,
          +profilePictureDefaultId: ?number,
          +email: ?string,
          +monday: ?number,
          +tuesday: ?number,
          +wednesday: ?number,
          +thursday: ?number,
          +friday: ?number,
          +saturday: ?number,
          +sunday: ?number,
          +harvestUser: ?boolean,
          +role: ?{|
            +id: string,
            +name: ?string,
          |},
        |}
      |}>
    |},
    +teams: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
          +teamPersons: ?{|
            +edges: ?$ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +person: ?{|
                  +id: string,
                  +active: ?boolean,
                  +firstName: ?string,
                  +lastName: ?string,
                  +fullName: ?string,
                  +initials: ?string,
                  +profilePictureId: ?string,
                  +profilePictureDefaultId: ?number,
                  +email: ?string,
                  +monday: ?number,
                  +tuesday: ?number,
                  +wednesday: ?number,
                  +thursday: ?number,
                  +friday: ?number,
                  +saturday: ?number,
                  +sunday: ?number,
                  +harvestUser: ?boolean,
                  +role: ?{|
                    +id: string,
                    +name: ?string,
                  |},
                |},
              |}
            |}>
          |},
        |}
      |}>
    |},
    +$fragmentRefs: ProjectPriorityDropdown_company$ref,
  |},
  +project: ?{|
    +customFieldValues?: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +key: ?string,
          +value: ?string,
          +displayName: ?string,
          +readOnly: ?boolean,
        |}
      |}>
    |},
    +id: string,
    +companyProjectId: ?number,
    +customProjectId: ?string,
    +isInProjectGroup: ?boolean,
    +projectGroupId: ?string,
    +name: ?string,
    +budgetType: ?BUDGET_TYPE,
    +budget: ?number,
    +useBaseline: ?boolean,
    +defaultPeriodPeriodicity: ?PERIODICITY,
    +defaultPeriodLength: ?number,
    +defaultPeriodBudgetType: ?PERIOD_BUDGET_TYPE,
    +defaultPeriodHoursAmount: ?number,
    +defaultPeriodPriceAmount: ?number,
    +defaultPeriodSettingSubtractValue: ?boolean,
    +defaultPeriodSettingRollValue: ?boolean,
    +githubRepositoryIds: ?$ReadOnlyArray<?number>,
    +useManualAllocations: ?boolean,
    +taskCostCalculationType: ?string,
    +unassignedTaskHourlyCost: ?number,
    +linkToPublicCreateTaskPage: ?string,
    +usePublicCreateTaskPassword: ?boolean,
    +publicCreateTaskPassword: ?string,
    +publicCreateTaskPageStatusColumn: ?{|
      +id: string,
      +name: ?string,
    |},
    +manualProgressOnProjectEnabled: ?boolean,
    +manualProgressOnPhasesEnabled: ?boolean,
    +manualProgressOnTasksEnabled: ?boolean,
    +revenueMarginLow: ?number,
    +revenueMarginHigh: ?number,
    +hasInvoices: ?boolean,
    +hasLockedPeriod: ?boolean,
    +hasLockedTime: ?boolean,
    +userCanDeleteProject: ?boolean,
    +userCantDeleteProjectReason: ?CANT_DELETE_PROJECT_REASON,
    +baselineWinChance: ?number,
    +teamsId: ?string,
    +sageProject: ?{|
      +id: string,
      +sageProjectId: ?string,
    |},
    +currentProjectStatus: ?{|
      +id: string,
      +color: ?string,
      +description: ?string,
      +day: ?number,
      +month: ?number,
      +year: ?number,
      +person: ?{|
        +id: string,
        +firstName: ?string,
        +lastName: ?string,
        +profilePictureId: ?string,
      |},
      +$fragmentRefs: ProjectStatus_projectStatus$ref,
    |},
    +projectStatuses: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +year: ?number,
          +month: ?number,
          +day: ?number,
          +hour: ?number,
          +minute: ?number,
          +second: ?number,
          +$fragmentRefs: ProjectStatus_projectStatus$ref,
        |}
      |}>
    |},
    +progressDetails: ?{|
      +id: string,
      +progress: ?number,
      +createdAt: ?string,
      +createdBy: ?{|
        +firstName: ?string,
        +lastName: ?string,
        +profilePictureId: ?string,
      |},
    |},
    +progressHistory: ?$ReadOnlyArray<?{|
      +id: string,
      +progress: ?number,
      +createdAt: ?string,
      +createdBy: ?{|
        +firstName: ?string,
        +lastName: ?string,
        +profilePictureId: ?string,
      |},
    |}>,
    +isProgramRevenueLocked: ?boolean,
    +programBudgetType: ?PROGRAM_BUDGET_TYPE,
    +programRemainingBudget: ?number,
    +program: ?{|
      +name: ?string,
      +prefix: ?string,
      +totalAllocation: ?number,
      +budgetValue: ?number,
      +revenueSetting: ?string,
      +members: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +role: ?string,
            +person: ?{|
              +id: string
            |},
          |}
        |}>
      |},
    |},
    +harvestProject: ?{|
      +id: string
    |},
    +harvestProjectId: ?string,
    +jiraSyncRemaining: ?boolean,
    +jiraCloudProject: ?{|
      +id: string,
      +name: ?string,
    |},
    +jiraServerProject: ?{|
      +id: string,
      +name: ?string,
    |},
    +isJiraProject: ?boolean,
    +slackChannelId: ?string,
    +slackChannel: ?{|
      +id: string
    |},
    +salesforceOpportunity: ?string,
    +unit4Project: ?{|
      +id: string,
      +name: ?string,
    |},
    +hubspotDeal: ?string,
    +pipedriveDeal: ?string,
    +vstsProject: ?string,
    +vstsAccount: ?string,
    +vstsArea: ?string,
    +vstsSyncBugs: ?boolean,
    +vstsTwoWaySync: ?boolean,
    +vstsUseEpics: ?boolean,
    +vstsUseNewSubtasks: ?boolean,
    +gitlabProjectId: ?string,
    +gitlabProjectName: ?string,
    +gitlabServerProjectId: ?string,
    +gitlabServerProjectName: ?string,
    +gitHubSyncTarget: ?number,
    +status: ?PROJECT_STATUS,
    +projectColor: ?string,
    +statusColumnsV2: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +category: ?STATUS_CATEGORY,
          +name: ?string,
        |}
      |}>
    |},
    +projectLabels: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +label: ?{|
            +id: string,
            +name: ?string,
            +color: ?string,
          |},
        |}
      |}>
    |},
    +description: ?string,
    +estimationUnit: ?string,
    +sprintTimeBox: ?boolean,
    +sprintLengthInDays: ?number,
    +projectStartYear: ?number,
    +projectStartMonth: ?number,
    +projectStartDay: ?number,
    +projectEndYear: ?number,
    +projectEndMonth: ?number,
    +projectEndDay: ?number,
    +taskLevels: ?number,
    +useTaskHierarchy: ?boolean,
    +useDeliverables: ?boolean,
    +minutesPerEstimationPoint: ?number,
    +billable: ?boolean,
    +remainingAutoCalculated: ?boolean,
    +useTaskOwner: ?boolean,
    +useTaskFollowers: ?boolean,
    +retainerPeriods: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string
        |}
      |}>
    |},
    +projectPersons: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +isContactPerson: ?boolean,
          +role: ?{|
            +id: string,
            +name: ?string,
          |},
          +person: ?{|
            +id: string,
            +firstName: ?string,
            +lastName: ?string,
            +fullName: ?string,
            +initials: ?string,
            +profiles: ?{|
              +edges: ?$ReadOnlyArray<?{|
                +node: ?{|
                  +id: string,
                  +name: ?string,
                |}
              |}>
            |},
            +permissions: ?$ReadOnlyArray<?string>,
            +email: ?string,
            +profilePictureId: ?string,
            +profilePictureDefaultId: ?number,
            +active: ?boolean,
            +isViewer: ?boolean,
            +harvestUser: ?boolean,
            +role: ?{|
              +id: string,
              +name: ?string,
            |},
            +personLabels: ?{|
              +edges: ?$ReadOnlyArray<?{|
                +node: ?{|
                  +id: string,
                  +label: ?{|
                    +id: string,
                    +name: ?string,
                  |},
                |}
              |}>
            |},
          |},
          +relationType: ?string,
        |}
      |}>
    |},
    +rateCard: ?{|
      +id: string,
      +currency: ?string,
      +disabledRoles: ?$ReadOnlyArray<?{|
        +id: string
      |}>,
    |},
    +client: ?{|
      +id: string,
      +clientBillTos: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +clientId: string,
            +billTo: ?{|
              +id: string
            |},
            +billFrom: ?{|
              +id: string
            |},
          |}
        |}>
      |},
    |},
    +tasks: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
          +companyTaskId: ?number,
          +statusColumnV2: ?{|
            +name: ?string,
            +category: ?STATUS_CATEGORY,
          |},
          +parentTaskId: ?string,
          +sageIntacctId: ?string,
        |}
      |}>
    |},
    +projectBillTos: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +clientBillToId: string,
          +projectId: string,
          +clientId: string,
          +invoiced: ?boolean,
          +billTo: ?{|
            +id: string,
            +name: string,
          |},
          +billFrom: ?{|
            +id: string,
            +name: string,
            +integration: string,
          |},
        |}
      |}>
    |},
    +$fragmentRefs: ProjectPriorityDropdown_project$ref & ProjectManualProgressConfiguration_project$ref & ProjectHeader_project$ref & SecondaryNavigation_project$ref & ValueCalculations_project$ref & JiraIntegrationSection_project$ref,
  |},
  +psProject: ?{|
    +$fragmentRefs: ProjectHeader_psProject$ref
  |},
  +$refType: ProjectSettingsPage_viewer$ref,
|};
export type ProjectSettingsPage_viewer$data = ProjectSettingsPage_viewer;
export type ProjectSettingsPage_viewer$key = {
  +$data?: ProjectSettingsPage_viewer$data,
  +$fragmentRefs: ProjectSettingsPage_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v3 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100000
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = [
  (v0/*: any*/),
  (v4/*: any*/)
],
v6 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ProfileTypeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Profile",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": (v5/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "vstsAccount",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v9 = {
  "kind": "Literal",
  "name": "first",
  "value": 10000
},
v10 = [
  (v0/*: any*/)
],
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "Role",
  "kind": "LinkedField",
  "name": "disabledRoles",
  "plural": true,
  "selections": (v10/*: any*/),
  "storageKey": null
},
v12 = [
  (v9/*: any*/)
],
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "initials",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "active",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profilePictureId",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profilePictureDefaultId",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "monday",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tuesday",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "wednesday",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thursday",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "friday",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "saturday",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sunday",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "harvestUser",
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "concreteType": "Role",
  "kind": "LinkedField",
  "name": "role",
  "plural": false,
  "selections": (v5/*: any*/),
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v31 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v32 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v33 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1000
  }
],
v34 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "color",
  "storageKey": null
},
v35 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v36 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "day",
  "storageKey": null
},
v37 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "month",
  "storageKey": null
},
v38 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "year",
  "storageKey": null
},
v39 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "ProjectStatus_projectStatus"
},
v40 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "progress",
    "storageKey": null
  },
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "Person",
    "kind": "LinkedField",
    "name": "createdBy",
    "plural": false,
    "selections": [
      (v13/*: any*/),
      (v14/*: any*/),
      (v18/*: any*/)
    ],
    "storageKey": null
  }
],
v41 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "category",
  "storageKey": null
},
v42 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientId",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectId",
      "type": "String"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "loadCustomFields",
      "type": "Boolean!"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "company",
          "allPersons"
        ]
      }
    ]
  },
  "name": "ProjectSettingsPage_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "actualPersonId",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "name",
          "value": "project_settings"
        }
      ],
      "kind": "ScalarField",
      "name": "component",
      "storageKey": "component(name:\"project_settings\")"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "language",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "githubConnected",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FeatureFlag",
      "kind": "LinkedField",
      "name": "availableFeatureFlags",
      "plural": true,
      "selections": [
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tier",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "forecastDemo",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Module",
          "kind": "LinkedField",
          "name": "modules",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "moduleType",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": (v3/*: any*/),
          "concreteType": "ProfileTypeConnection",
          "kind": "LinkedField",
          "name": "profiles",
          "plural": false,
          "selections": (v6/*: any*/),
          "storageKey": "profiles(first:100000)"
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "sageIntacctTimeRegsFromForecast",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isChargebeeCustomer",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "characterLimit",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "salesforceEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "newSalesforceEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hubspotAccountId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "sageIntacctEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "salesforceUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "githubEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "githubInstallationId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "vstsEnabled",
          "storageKey": null
        },
        (v7/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unit4Enabled",
          "storageKey": null
        },
        (v8/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "harvestEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slackEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "newSlackEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "jiraCloudEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "jiraServerEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "gitlabEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "gitlabServerEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "gitlabServerUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "teamsEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "sageIntacctConnectionEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "pipedriveCompanyDomain",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isUsingSchedulingPlanMode",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isUsingProjectAllocation",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isUsingMixedAllocation",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            (v9/*: any*/),
            {
              "kind": "Literal",
              "name": "labelType",
              "value": "PROJECT"
            }
          ],
          "concreteType": "LabelTypeConnection",
          "kind": "LinkedField",
          "name": "labels",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "LabelTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Label",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "LabelDropdown_labels"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "labels(first:10000,labelType:\"PROJECT\")"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ClientTypeConnection",
          "kind": "LinkedField",
          "name": "clients",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ClientTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Client",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v4/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "logoId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "logoDefaultId",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "RateCardTypeConnection",
          "kind": "LinkedField",
          "name": "rateCards",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "RateCardTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "RateCard",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v11/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "RateCardDropdown_rateCards"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": (v12/*: any*/),
          "concreteType": "ExchangeRateTypeConnection",
          "kind": "LinkedField",
          "name": "exchangeRates",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ExchangeRateTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ExchangeRate",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v8/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "rate",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "exchangeRates(first:10000)"
        },
        {
          "alias": null,
          "args": (v12/*: any*/),
          "concreteType": "RoleTypeConnection",
          "kind": "LinkedField",
          "name": "roles",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "RoleTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Role",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v5/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "roles(first:10000)"
        },
        {
          "alias": "allPersons",
          "args": null,
          "concreteType": "PersonTypeConnection",
          "kind": "LinkedField",
          "name": "__Company_allPersons_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PersonTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Person",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v13/*: any*/),
                    (v14/*: any*/),
                    (v15/*: any*/),
                    (v16/*: any*/),
                    (v17/*: any*/),
                    (v18/*: any*/),
                    (v19/*: any*/),
                    (v20/*: any*/),
                    (v21/*: any*/),
                    (v22/*: any*/),
                    (v23/*: any*/),
                    (v24/*: any*/),
                    (v25/*: any*/),
                    (v26/*: any*/),
                    (v27/*: any*/),
                    (v28/*: any*/),
                    (v29/*: any*/),
                    (v30/*: any*/)
                  ],
                  "storageKey": null
                },
                (v31/*: any*/)
              ],
              "storageKey": null
            },
            (v32/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": (v33/*: any*/),
          "concreteType": "TeamTypeConnection",
          "kind": "LinkedField",
          "name": "teams",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TeamTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Team",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v4/*: any*/),
                    {
                      "alias": null,
                      "args": (v33/*: any*/),
                      "concreteType": "TeamPersonTypeConnection",
                      "kind": "LinkedField",
                      "name": "teamPersons",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "TeamPersonTypeEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "TeamPerson",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                (v0/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "Person",
                                  "kind": "LinkedField",
                                  "name": "person",
                                  "plural": false,
                                  "selections": [
                                    (v0/*: any*/),
                                    (v17/*: any*/),
                                    (v13/*: any*/),
                                    (v14/*: any*/),
                                    (v15/*: any*/),
                                    (v16/*: any*/),
                                    (v18/*: any*/),
                                    (v19/*: any*/),
                                    (v20/*: any*/),
                                    (v21/*: any*/),
                                    (v22/*: any*/),
                                    (v23/*: any*/),
                                    (v24/*: any*/),
                                    (v25/*: any*/),
                                    (v26/*: any*/),
                                    (v27/*: any*/),
                                    (v28/*: any*/),
                                    (v29/*: any*/)
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": "teamPersons(first:1000)"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "teams(first:1000)"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProjectPriorityDropdown_company"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "projectId"
        }
      ],
      "concreteType": "ProjectType",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "companyProjectId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "customProjectId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isInProjectGroup",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectGroupId",
          "storageKey": null
        },
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "budgetType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "budget",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "useBaseline",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "defaultPeriodPeriodicity",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "defaultPeriodLength",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "defaultPeriodBudgetType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "defaultPeriodHoursAmount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "defaultPeriodPriceAmount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "defaultPeriodSettingSubtractValue",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "defaultPeriodSettingRollValue",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "githubRepositoryIds",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "useManualAllocations",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "taskCostCalculationType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unassignedTaskHourlyCost",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "linkToPublicCreateTaskPage",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "usePublicCreateTaskPassword",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "publicCreateTaskPassword",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "StatusColumnV2",
          "kind": "LinkedField",
          "name": "publicCreateTaskPageStatusColumn",
          "plural": false,
          "selections": (v5/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "manualProgressOnProjectEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "manualProgressOnPhasesEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "manualProgressOnTasksEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "revenueMarginLow",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "revenueMarginHigh",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasInvoices",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasLockedPeriod",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasLockedTime",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "userCanDeleteProject",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "userCantDeleteProjectReason",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "baselineWinChance",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "teamsId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SageProjectType",
          "kind": "LinkedField",
          "name": "sageProject",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "sageProjectId",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectStatus",
          "kind": "LinkedField",
          "name": "currentProjectStatus",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v34/*: any*/),
            (v35/*: any*/),
            (v36/*: any*/),
            (v37/*: any*/),
            (v38/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Person",
              "kind": "LinkedField",
              "name": "person",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v13/*: any*/),
                (v14/*: any*/),
                (v18/*: any*/)
              ],
              "storageKey": null
            },
            (v39/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": "projectStatuses",
          "args": null,
          "concreteType": "ProjectStatusTypeConnection",
          "kind": "LinkedField",
          "name": "__Project_projectStatuses_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProjectStatusTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProjectStatus",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v38/*: any*/),
                    (v37/*: any*/),
                    (v36/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hour",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "minute",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "second",
                      "storageKey": null
                    },
                    (v30/*: any*/),
                    (v39/*: any*/)
                  ],
                  "storageKey": null
                },
                (v31/*: any*/)
              ],
              "storageKey": null
            },
            (v32/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectProgress",
          "kind": "LinkedField",
          "name": "progressDetails",
          "plural": false,
          "selections": (v40/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectProgress",
          "kind": "LinkedField",
          "name": "progressHistory",
          "plural": true,
          "selections": (v40/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isProgramRevenueLocked",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "programBudgetType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "programRemainingBudget",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ProgramType",
          "kind": "LinkedField",
          "name": "program",
          "plural": false,
          "selections": [
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "prefix",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalAllocation",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "budgetValue",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "revenueSetting",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ProjectServiceProgramMemberTypeConnection",
              "kind": "LinkedField",
              "name": "members",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProjectServiceProgramMemberTypeEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ProjectServiceProgramMemberType",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "role",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Person",
                          "kind": "LinkedField",
                          "name": "person",
                          "plural": false,
                          "selections": (v10/*: any*/),
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "HarvestProjectType",
          "kind": "LinkedField",
          "name": "harvestProject",
          "plural": false,
          "selections": (v10/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "harvestProjectId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "jiraSyncRemaining",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "JiraCloudProjectType",
          "kind": "LinkedField",
          "name": "jiraCloudProject",
          "plural": false,
          "selections": (v5/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "JiraServerProjectType",
          "kind": "LinkedField",
          "name": "jiraServerProject",
          "plural": false,
          "selections": (v5/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isJiraProject",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slackChannelId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SlackChannelType",
          "kind": "LinkedField",
          "name": "slackChannel",
          "plural": false,
          "selections": (v10/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "salesforceOpportunity",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Unit4ProjectType",
          "kind": "LinkedField",
          "name": "unit4Project",
          "plural": false,
          "selections": (v5/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hubspotDeal",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "pipedriveDeal",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "vstsProject",
          "storageKey": null
        },
        (v7/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "vstsArea",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "vstsSyncBugs",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "vstsTwoWaySync",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "vstsUseEpics",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "vstsUseNewSubtasks",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "gitlabProjectId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "gitlabProjectName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "gitlabServerProjectId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "gitlabServerProjectName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "gitHubSyncTarget",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectColor",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 1000000
            }
          ],
          "concreteType": "StatusColumnV2TypeConnection",
          "kind": "LinkedField",
          "name": "statusColumnsV2",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "StatusColumnV2TypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "StatusColumnV2",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v41/*: any*/),
                    (v4/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "statusColumnsV2(first:1000000)"
        },
        {
          "alias": null,
          "args": (v12/*: any*/),
          "concreteType": "ProjectLabelTypeConnection",
          "kind": "LinkedField",
          "name": "projectLabels",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProjectLabelTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProjectLabel",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Label",
                      "kind": "LinkedField",
                      "name": "label",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v4/*: any*/),
                        (v34/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "projectLabels(first:10000)"
        },
        (v35/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "estimationUnit",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "sprintTimeBox",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "sprintLengthInDays",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectStartYear",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectStartMonth",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectStartDay",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectEndYear",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectEndMonth",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectEndDay",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "taskLevels",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "useTaskHierarchy",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "useDeliverables",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "minutesPerEstimationPoint",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "billable",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "remainingAutoCalculated",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "useTaskOwner",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "useTaskFollowers",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 1
            }
          ],
          "concreteType": "RetainerPeriodTypeConnection",
          "kind": "LinkedField",
          "name": "retainerPeriods",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "RetainerPeriodTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "RetainerPeriodType",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v10/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "retainerPeriods(first:1)"
        },
        {
          "alias": "projectPersons",
          "args": null,
          "concreteType": "ProjectPersonTypeConnection",
          "kind": "LinkedField",
          "name": "__Project_projectPersons_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProjectPersonTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProjectPerson",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "isContactPerson",
                      "storageKey": null
                    },
                    (v29/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Person",
                      "kind": "LinkedField",
                      "name": "person",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v13/*: any*/),
                        (v14/*: any*/),
                        (v15/*: any*/),
                        (v16/*: any*/),
                        {
                          "alias": null,
                          "args": (v12/*: any*/),
                          "concreteType": "ProfileTypeConnection",
                          "kind": "LinkedField",
                          "name": "profiles",
                          "plural": false,
                          "selections": (v6/*: any*/),
                          "storageKey": "profiles(first:10000)"
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "permissions",
                          "storageKey": null
                        },
                        (v20/*: any*/),
                        (v18/*: any*/),
                        (v19/*: any*/),
                        (v17/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "isViewer",
                          "storageKey": null
                        },
                        (v28/*: any*/),
                        (v29/*: any*/),
                        {
                          "alias": null,
                          "args": (v33/*: any*/),
                          "concreteType": "PersonLabelTypeConnection",
                          "kind": "LinkedField",
                          "name": "personLabels",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "PersonLabelTypeEdge",
                              "kind": "LinkedField",
                              "name": "edges",
                              "plural": true,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "PersonLabel",
                                  "kind": "LinkedField",
                                  "name": "node",
                                  "plural": false,
                                  "selections": [
                                    (v0/*: any*/),
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "Label",
                                      "kind": "LinkedField",
                                      "name": "label",
                                      "plural": false,
                                      "selections": (v5/*: any*/),
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": "personLabels(first:1000)"
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "relationType",
                      "storageKey": null
                    },
                    (v30/*: any*/)
                  ],
                  "storageKey": null
                },
                (v31/*: any*/)
              ],
              "storageKey": null
            },
            (v32/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "RateCard",
          "kind": "LinkedField",
          "name": "rateCard",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v8/*: any*/),
            (v11/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Client",
          "kind": "LinkedField",
          "name": "client",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": "clientBillTos",
              "args": null,
              "concreteType": "ClientBillToTypeConnection",
              "kind": "LinkedField",
              "name": "__BillFrom_clientBillTos_connection",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ClientBillToTypeEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ClientBillTo",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v42/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "BillTo",
                          "kind": "LinkedField",
                          "name": "billTo",
                          "plural": false,
                          "selections": (v10/*: any*/),
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "BillFrom",
                          "kind": "LinkedField",
                          "name": "billFrom",
                          "plural": false,
                          "selections": (v10/*: any*/),
                          "storageKey": null
                        },
                        (v30/*: any*/)
                      ],
                      "storageKey": null
                    },
                    (v31/*: any*/)
                  ],
                  "storageKey": null
                },
                (v32/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": (v3/*: any*/),
          "concreteType": "TaskTypeConnection",
          "kind": "LinkedField",
          "name": "tasks",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TaskTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Task",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v4/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "companyTaskId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "StatusColumnV2",
                      "kind": "LinkedField",
                      "name": "statusColumnV2",
                      "plural": false,
                      "selections": [
                        (v4/*: any*/),
                        (v41/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "parentTaskId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "sageIntacctId",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "tasks(first:100000)"
        },
        {
          "alias": "projectBillTos",
          "args": null,
          "concreteType": "ProjectBillToTypeConnection",
          "kind": "LinkedField",
          "name": "__Project_projectBillTos_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProjectBillToTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProjectBillTo",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "clientBillToId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "projectId",
                      "storageKey": null
                    },
                    (v42/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "invoiced",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "BillTo",
                      "kind": "LinkedField",
                      "name": "billTo",
                      "plural": false,
                      "selections": (v5/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "BillFrom",
                      "kind": "LinkedField",
                      "name": "billFrom",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v4/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "integration",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    (v30/*: any*/)
                  ],
                  "storageKey": null
                },
                (v31/*: any*/)
              ],
              "storageKey": null
            },
            (v32/*: any*/)
          ],
          "storageKey": null
        },
        {
          "condition": "loadCustomFields",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CustomFieldValueTypeConnection",
              "kind": "LinkedField",
              "name": "customFieldValues",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "CustomFieldValueTypeEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "CustomFieldValue",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v1/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "value",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "displayName",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "readOnly",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ]
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProjectPriorityDropdown_project"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProjectManualProgressConfiguration_project"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProjectHeader_project"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SecondaryNavigation_project"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ValueCalculations_project"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "JiraIntegrationSection_project"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "companyProjectId",
          "variableName": "projectId"
        }
      ],
      "concreteType": "ProjectServiceProjectType",
      "kind": "LinkedField",
      "name": "psProject",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProjectHeader_psProject"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = '7138fc909d7b1a0b73d6300d77c5bc8e';

module.exports = node;
