/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type FocusModal_viewer$ref = any;
export type GLOBAL_FILTER_CONTEXT = "INVOICES" | "LABELS" | "PEOPLE" | "PLACEHOLDERS" | "PROJECTS" | "RESOURCES" | "SKILLS" | "TASKS" | "TIME_REGS" | "%future added value";
export type GLOBAL_FILTER_FIELD = "APPROVAL_STATUS" | "ASSIGNED" | "ASSIGNED_SELF" | "BILLABLE" | "BILLABLE_TIME_REG" | "BILL_FROM" | "BILL_TO" | "BLOCKED" | "BUG" | "CLIENT" | "CLIENT_GUEST_USERS" | "CLIENT_TIME_REG" | "COMPANY_TASK_ID" | "CONTACT" | "CREATED_AT" | "DEADLINE" | "DEPARTMENT" | "DEPARTMENT_TIME_REG" | "DEPENDENCIES" | "DESCRIPTION" | "END_DATE" | "END_DATE_PROJECT" | "END_DATE_TIME_REG" | "FILTER_BY_TIME_REG_SETTINGS" | "FOLLOWER" | "FORECAST" | "HIGH_PRIORITY" | "INTERNAL_TIME" | "INTERNAL_TIME_REG" | "INVOICED" | "LABELS" | "LABEL_TIME_REG" | "NAME" | "NON_CLIENT_TIME_REG" | "NON_DEPARTMENT_TIME_REG" | "NON_PHASE_TIME_REG" | "NON_PROJECT_TIME_REG" | "NON_ROLE_TIME_REG" | "NON_SPRINT_TIME_REG" | "NON_TASK_TIME_REG" | "NO_CONTACT" | "OWNER" | "PERMISSION_LEVEL" | "PERSON" | "PERSON_TIME_REG" | "PHASE_CATEGORY" | "PHASE_TIME_REG" | "PRIORITY_LEVEL" | "PROGRAM" | "PROJECT" | "PROJECT_BUDGET_TYPE" | "PROJECT_DEFAULT_PERIOD_BUDGET_TYPE" | "PROJECT_FOLLOWER" | "PROJECT_GROUP" | "PROJECT_GROUP_SPRINT" | "PROJECT_GROUP_STATUS" | "PROJECT_LABELS" | "PROJECT_MANUAL_PROGRESS" | "PROJECT_OWNER" | "PROJECT_PERSON" | "PROJECT_PHASE" | "PROJECT_SPRINT" | "PROJECT_STAGE" | "PROJECT_STATUS" | "PROJECT_TIME_REG" | "RATE_CARD" | "RECENT_ACTIVITY" | "RECENT_TASK_ACTIVITY" | "RECENT_TIME" | "REMAINING" | "RETAINER_PERIOD" | "ROLE" | "ROLE_TIME_REG" | "SET_DONE_AT" | "SKILL" | "SPRINT_CATEGORY" | "SPRINT_TIME_REG" | "STARRED" | "START_DATE" | "START_DATE_PROJECT" | "START_DATE_TIME_REG" | "STATUS" | "SUB_TASKS" | "TASK" | "TASK_APPROVED" | "TASK_HIERARCHY" | "TASK_LEVEL" | "TASK_TIME_REG" | "TEAM" | "TEAM_MEMBER" | "TEAM_TIME_REG" | "TIME_OFF" | "TIME_OFF_TIME_REG" | "TIME_REGS" | "UPDATED_AT" | "WITH_TASKS" | "WITH_TIME_REG" | "%future added value";
export type GLOBAL_FILTER_LOGICAL_OPERATOR = "AND" | "OR" | "%future added value";
export type GLOBAL_FILTER_OPERATOR = "ALL_IN" | "CONTAINS" | "CONTAINS_NOT" | "GREATER" | "GREATER_OR_EQUAL" | "IN" | "IS" | "IS_ALL" | "IS_NOT" | "IS_NOT_ALL" | "LESS" | "LESS_OR_EQUAL" | "NOT_ALL_IN" | "NOT_IN" | "%future added value";
export type TaskSearchQueryType = {|
  filters?: ?$ReadOnlyArray<?GlobalSearchFilterType>
|};
export type GlobalSearchFilterType = {|
  field?: ?GLOBAL_FILTER_FIELD,
  operator?: ?GLOBAL_FILTER_OPERATOR,
  value?: ?$ReadOnlyArray<?string>,
  context?: ?GLOBAL_FILTER_CONTEXT,
  filters?: ?$ReadOnlyArray<?GlobalSearchFilterType>,
  logicalOperator?: ?GLOBAL_FILTER_LOGICAL_OPERATOR,
|};
export type FocusModalModal_QueryVariables = {|
  projectId?: ?string,
  resourceSearchQuery?: ?TaskSearchQueryType,
  timeRegSearchQuery?: ?TaskSearchQueryType,
  startYear: number,
  startMonth: number,
  startDay: number,
  endYear: number,
  endMonth: number,
  endDay: number,
|};
export type FocusModalModal_QueryResponse = {|
  +viewer: ?{|
    +component: ?string,
    +actualPersonId: ?string,
    +id: string,
    +$fragmentRefs: FocusModal_viewer$ref,
  |}
|};
export type FocusModalModal_Query = {|
  variables: FocusModalModal_QueryVariables,
  response: FocusModalModal_QueryResponse,
|};
*/


/*
query FocusModalModal_Query(
  $projectId: ID
  $resourceSearchQuery: TaskSearchQueryType
  $timeRegSearchQuery: TaskSearchQueryType
  $startYear: Int!
  $startMonth: Int!
  $startDay: Int!
  $endYear: Int!
  $endMonth: Int!
  $endDay: Int!
) {
  viewer {
    component(name: "focus_modal")
    actualPersonId
    id
    ...FocusModal_viewer_3ABLNT
  }
}

fragment FocusModal_viewer_3ABLNT on Viewer {
  company {
    resourceBasedPersons(first: 10000000, searchQuery: $resourceSearchQuery, onlyProjectData: true, startYear: $startYear, startMonth: $startMonth, startDay: $startDay, endYear: $endYear, endMonth: $endMonth, endDay: $endDay) {
      edges {
        node {
          id
          resourceNumbers {
            plannedTotalMinutes
          }
        }
      }
    }
    id
  }
  project(internalId: $projectId) {
    id
    name
    projectPersons {
      edges {
        node {
          id
          person {
            id
            fullName
            profilePictureId
            profilePictureDefaultId
            totalMinutesRegistered(searchQuery: $timeRegSearchQuery)
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectId",
    "type": "ID"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "resourceSearchQuery",
    "type": "TaskSearchQueryType"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "timeRegSearchQuery",
    "type": "TaskSearchQueryType"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "startYear",
    "type": "Int!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "startMonth",
    "type": "Int!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "startDay",
    "type": "Int!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "endYear",
    "type": "Int!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "endMonth",
    "type": "Int!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "endDay",
    "type": "Int!"
  }
],
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "focus_modal"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"focus_modal\")"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "kind": "Variable",
  "name": "endDay",
  "variableName": "endDay"
},
v5 = {
  "kind": "Variable",
  "name": "endMonth",
  "variableName": "endMonth"
},
v6 = {
  "kind": "Variable",
  "name": "endYear",
  "variableName": "endYear"
},
v7 = {
  "kind": "Variable",
  "name": "startDay",
  "variableName": "startDay"
},
v8 = {
  "kind": "Variable",
  "name": "startMonth",
  "variableName": "startMonth"
},
v9 = {
  "kind": "Variable",
  "name": "startYear",
  "variableName": "startYear"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FocusModalModal_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "args": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "kind": "Variable",
                "name": "projectId",
                "variableName": "projectId"
              },
              {
                "kind": "Variable",
                "name": "resourceSearchQuery",
                "variableName": "resourceSearchQuery"
              },
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "kind": "Variable",
                "name": "timeRegSearchQuery",
                "variableName": "timeRegSearchQuery"
              }
            ],
            "kind": "FragmentSpread",
            "name": "FocusModal_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FocusModalModal_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 10000000
                  },
                  {
                    "kind": "Literal",
                    "name": "onlyProjectData",
                    "value": true
                  },
                  {
                    "kind": "Variable",
                    "name": "searchQuery",
                    "variableName": "resourceSearchQuery"
                  },
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/)
                ],
                "concreteType": "PersonTypeConnection",
                "kind": "LinkedField",
                "name": "resourceBasedPersons",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PersonTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Person",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ResourceNumbers",
                            "kind": "LinkedField",
                            "name": "resourceNumbers",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "plannedTotalMinutes",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "internalId",
                "variableName": "projectId"
              }
            ],
            "concreteType": "ProjectType",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectPersonTypeConnection",
                "kind": "LinkedField",
                "name": "projectPersons",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProjectPersonTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProjectPerson",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Person",
                            "kind": "LinkedField",
                            "name": "person",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "fullName",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "profilePictureId",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "profilePictureDefaultId",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": [
                                  {
                                    "kind": "Variable",
                                    "name": "searchQuery",
                                    "variableName": "timeRegSearchQuery"
                                  }
                                ],
                                "kind": "ScalarField",
                                "name": "totalMinutesRegistered",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "FocusModalModal_Query",
    "operationKind": "query",
    "text": "query FocusModalModal_Query(\n  $projectId: ID\n  $resourceSearchQuery: TaskSearchQueryType\n  $timeRegSearchQuery: TaskSearchQueryType\n  $startYear: Int!\n  $startMonth: Int!\n  $startDay: Int!\n  $endYear: Int!\n  $endMonth: Int!\n  $endDay: Int!\n) {\n  viewer {\n    component(name: \"focus_modal\")\n    actualPersonId\n    id\n    ...FocusModal_viewer_3ABLNT\n  }\n}\n\nfragment FocusModal_viewer_3ABLNT on Viewer {\n  company {\n    resourceBasedPersons(first: 10000000, searchQuery: $resourceSearchQuery, onlyProjectData: true, startYear: $startYear, startMonth: $startMonth, startDay: $startDay, endYear: $endYear, endMonth: $endMonth, endDay: $endDay) {\n      edges {\n        node {\n          id\n          resourceNumbers {\n            plannedTotalMinutes\n          }\n        }\n      }\n    }\n    id\n  }\n  project(internalId: $projectId) {\n    id\n    name\n    projectPersons {\n      edges {\n        node {\n          id\n          person {\n            id\n            fullName\n            profilePictureId\n            profilePictureDefaultId\n            totalMinutesRegistered(searchQuery: $timeRegSearchQuery)\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6cebc816ccf093a9e6744ef0a953b848';

module.exports = node;
