import React, {useCallback, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import Moment from 'moment';
import {useHistory, withRouter} from 'react-router-dom';
import {cloneDeep, truncate} from 'lodash';
import {createFragmentContainer, graphql} from 'react-relay';
import CreateRetainerPeriodMutation from '../../../mutations/create_retainer_period_mutation';
import Util from '../../../forecast-app/shared/util/util';
import {
	BUTTON_COLOR,
	BUTTON_STYLE,
	BUTTON_VARIANT,
	CAP_SETTING,
	ELEMENT_TYPE,
	EMPTY_STATE,
	PERIOD_BUDGET_TYPE,
} from '../../../constants';
import {createToast} from '../../../forecast-app/shared/components/toasts/another-toast/toaster';
import RetainerPeriod from '../../../components/new-ui/retainer_period/retainer_period';
import RetainerPeriodWarningTab from '../../../components/new-ui/retainer_period/retainer_period_warning_tab';
import HeaderBar from '../../../forecast-app/shared/components/headers/header-bar/header_bar';
import Button from '../../../forecast-app/shared/components/buttons/button/button';
import {Bar} from 'react-chartjs-2';
import {MODAL_TYPE, showModal} from '../../../forecast-app/shared/components/modals/generic_modal_conductor';
import PeriodRolloverTexture from '../../../images/v2/components/periodbar-rollover.png';
import PeriodSubtractionTexture from '../../../images/v2/components/periodbar-subtract.png';
import PeriodStandardTexture from '../../../images/v2/components/periodbar-standard.png';
import {getGradients} from '../../../components/new-ui/project/project-budget-v3/util/ChartsUtil.js';
import * as tracking from '../../../tracking';
import UpdateProjectMutation from '../../../mutations/update_project_mutation.modern';
import EmptyState from '../../../forecast-app/shared/components/empty-states/empty_state';
import {hasFeatureFlag, usingTimeApproval} from '../../../forecast-app/shared/util/FeatureUtil';
import {
	getLastPeriodEndDateForSelectedPeriodCount,
	getTimeRegsForPeriod,
	sortByStartDate,
	timeRegIsInConflict,
} from '../../../components/new-ui/retainer_period/RetainerPeriodUtil';
import {hasSomePermission} from '../../../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../Permissions';
import {trackEvent} from '../../../tracking/amplitude/TrackingV2';
import ProjectHeader from '../../../forecast-app/project-tab/projects/shared/ProjectHeader';
import Checkbox from '../../../components/new-ui/check_box';
import {BulkSelectPopup} from 'web-components';
import {Icon} from '@forecast-it/design-system';
import ForecastTooltip from '../../../forecast-app/shared/components/tooltips/ForecastTooltip';
import {
	bulkChangePeriodTarget,
	bulkCreateInvoices,
	bulkCSVDownload,
	bulkDeletePeriod,
	bulkLockPeriod,
	bulkUnlockPeriod,
} from '../../../components/new-ui/retainer_period/RetainerBulkLogic';
import CustomScrollDiv from '../../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import {useProjectProgramFinancials} from '../../../forecast-app/project-tab/programs/hooks/useProjectProgramFinancials';
import {triggerFinancialServiceCalculationAndWait} from '../../../forecast-app/project-tab/programs/hooks/useFinancialServiceUpdate';
import {useSecondaryNavState} from '../../../forecast-app/navigation/secondary/SecondaryNavigationStateManager';

import {BarElement, Chart as ChartJS} from 'chart.js';
import {dispatch, EVENT_ID, subscribe, unsubscribe} from '../../event_manager';
import FinancialCalculationTrigger, {
	financialCalculationTriggerQuery,
} from '../../../components/new-ui/project/project-budget-v3/loaders/FinancialCalculationTrigger';
import {LoadMore} from '../../../components/loaders/LoadMore';
import {projectUrl} from '../../../directApi';
import {getTaskUrl, pathIncludesTask} from '../../../forecast-app/shared/util/UrlUtil';

ChartJS.register(BarElement);

export const projectRetainerPeriods = ({viewer, children, retry}) => {
	const history = useHistory();
	const intl = useIntl();
	const hasFinancialServiceFlag = hasFeatureFlag('planned_values_on_retainer_periods');
	const hasRolloverFlag = hasFeatureFlag('rollover_values_on_retainer_periods');

	const [isProgramValidationDataReady, setIsProgramValidationDataReady] = useState(false);
	const [isAllSelected, setAllSelected] = useState(false);
	const {expanded: sideNavExpanded, locked: sideNavLocked} = useSecondaryNavState()
		? useSecondaryNavState()
		: {expanded: false, locked: false};
	const [retryCallback, setRetryCallback] = useState();
	const [retryProcessing, setRetryProcessing] = useState(false);
	const [financialCalculationTriggerTimestamp, setFinancialCalculationTriggerTimestamp] = useState();
	const [selectedPeriodRange, setSelectedPeriodRange] = useState(
		localStorage.getItem('RETAINER_SELECTED_TAB') ? localStorage.getItem('RETAINER_SELECTED_TAB') : 'ALL'
	);
	const [selectedPeriods, setSelectedPeriods] = useState([]);
	const [actionOnValidationReady, setActionOnValidationReady] = useState();

	let lastViewedPage;
	if (viewer.projectGroup) {
		lastViewedPage = localStorage.getItem('project-group-section-last-viewed') || 'workflow';
		if (lastViewedPage === 'periods' || lastViewedPage === 'retainer-tracking') lastViewedPage = 'task-board';
		history.push('/connected/X-' + viewer.projectGroup.companyProjectGroupId + '/' + lastViewedPage);
	} else if (viewer.project && viewer.project.budgetType !== 'RETAINER') {
		lastViewedPage = localStorage.getItem('project-section-last-viewed') || 'workflow';
		if (lastViewedPage === 'periods' || lastViewedPage === 'retainer-tracking') lastViewedPage = 'task-board';
		history.push(projectUrl(viewer.project.companyProjectId, viewer.project.customProjectId) + '/' + lastViewedPage);
	}

	const ctx = document.createElement('canvas').getContext('2d');
	const negativeImg = new Image();
	const positiveImg = new Image();
	const standardImg = new Image();
	negativeImg.src = PeriodSubtractionTexture;
	positiveImg.src = PeriodRolloverTexture;
	standardImg.src = PeriodStandardTexture;
	const negativePattern = ctx.createPattern(negativeImg, 'repeat');
	const positivePattern = ctx.createPattern(positiveImg, 'repeat');
	const standardPattern = ctx.createPattern(standardImg, 'repeat');
	const {getRetainerPeriodProgramBudgetInfo, getProgramBudgetInfo, refetch} = useProjectProgramFinancials(
		viewer.project.companyProjectId,
		setIsProgramValidationDataReady
	);
	useEffect(() => {
		if (actionOnValidationReady && isProgramValidationDataReady) {
			actionOnValidationReady();
			setActionOnValidationReady(undefined);
		}
	}, [isProgramValidationDataReady]);

	let programBudgetInfo = getProgramBudgetInfo(viewer.actualPersonId);

	const project = viewer.project;
	const currency = project.rateCard ? project.rateCard.currency : viewer.company.currency;

	const timeRegs = project.timeRegistrations.edges.filter(timeReg => {
		const approved = timeReg.node.task ? timeReg.node.task.approved : true;

		return approved && timeReg.node.billableMinutesRegistered;
	});
	const timeRegsByPeriodId = new Map();
	project.retainerPeriods.edges.forEach(period => {
		const periodTimeRegs = getTimeRegsForPeriod(timeRegs, period.node, false);
		const conflictTimeRegs = [];
		const nonConflictTimeRegs = [];

		periodTimeRegs.forEach(timeReg => {
			if (timeRegIsInConflict(timeReg.node, period.node)) {
				conflictTimeRegs.push(timeReg);
			} else {
				nonConflictTimeRegs.push(timeReg);
			}
		});
		timeRegsByPeriodId.set(period.node.id, {
			conflictTimeRegs,
			nonConflictTimeRegs,
		});
	});

	const updateProject = useCallback(
		props => {
			if (retryCallback) {
				retryCallback();
				setRetryProcessing(true);
			}
		},
		[retryCallback, setRetryProcessing]
	);

	useEffect(() => {
		const path = history.location.pathname;
		if (!Util.AuthorizeViewerAccess('project-periods')) {
			if (pathIncludesTask(path)) {
				history.push(
					projectUrl(viewer.project.companyProjectId, viewer.project.customProjectId) + '/workflow' + getTaskUrl(path)
				);
			} else {
				// if user doesnt have access rights to view this page redirect to no access page
				history.push('/not-authorized');
				Util.localStorageSetItem('project-section-last-viewed', 'workflow');
			}
		}

		document.title = 'Periods - ' + viewer.project.name + ' - Forecast';
		tracking.trackPage('Retainer-periods');
	}, []);

	useEffect(() => {
		subscribe(EVENT_ID.RETAINER_UPDATE_PROJECT, updateProject);
		return () => {
			unsubscribe(EVENT_ID.RETAINER_UPDATE_PROJECT, updateProject);
		};
	}, [updateProject]);

	useEffect(() => {
		// If a period was deleted, make sure it's not selected anymore
		const newSelection = selectedPeriods.filter(selectedPeriod =>
			project.retainerPeriods.edges.some(period => period.node.id === selectedPeriod.id)
		);
		setSelectedPeriods(newSelection);
		if (!newSelection.length) {
			setAllSelected(false);
		}
	}, [project.retainerPeriods]);

	const handleRetryCompletion = result => {
		if (result.isSmallProject) {
			retry();
		} else {
			triggerFinancialServiceCalculationAndWait(viewer.project.id).then(() => {
				retry();
			});
		}
	};

	function handleFinancialCalculationTriggerResponse(financialTriggerRetry, result) {
		if (!retryCallback) {
			// Is Initial load
			setRetryCallback(() => financialTriggerRetry);
			if (result.isSmallProject) {
				retry();
			}
		}
		if (retryProcessing && result.timestamp !== financialCalculationTriggerTimestamp) {
			handleRetryCompletion(result);
			setRetryProcessing(false);
		}
	}

	const filterByActiveSelection = elem => {
		//Creating a new period with a selectedPeriod result as an node full of null values
		if (elem.node.name === null) return false;

		const today = Moment();
		const elemStartDate = Util.CreateMomentDate(elem.node.startYear, elem.node.startMonth, elem.node.startDay);
		const elemEndDate = Util.CreateMomentDate(elem.node.endYear, elem.node.endMonth, elem.node.endDay);
		switch (selectedPeriodRange) {
			case 'FUTURE': {
				return elemStartDate.isAfter(today, 'day');
			}
			case 'PAST': {
				return elemEndDate.isBefore(today, 'day');
			}
			case 'CURRENT': {
				return elemStartDate.isSameOrBefore(today, 'day') && elemEndDate.isSameOrAfter(today, 'day');
			}
			default:
				return true;
		}
	};

	const addPeriod = () => {
		const {formatMessage} = intl;
		const project = viewer.project;
		const sortedPeriods = cloneDeep(project.retainerPeriods.edges).sort(sortByStartDate);
		const firstRetainerPeriod = sortedPeriods[0];
		const latestRetainerPeriod = sortedPeriods[sortedPeriods.length - 1];
		// capSettingCondition : To make it work with and without feature flag
		const capSettingCondition = programBudgetInfo.isProgramRevenueLocked !== CAP_SETTING.ALWAYS_PERMITTED;
		const existingValue = project.retainerPeriods.edges.reduce(
			(acc, period) =>
				acc +
				(project.defaultPeriodBudgetType === PERIOD_BUDGET_TYPE.FIXED_HOURS
					? period.node.periodHoursAmount
					: period.node.periodPriceAmount),
			0
		);

		if (programBudgetInfo.isInFixedPriceProgram && !programBudgetInfo.canManageProgram) {
			showModal({
				type: MODAL_TYPE.NOT_PERMITTED_PROJECT_BUDGET_CHANGE,
				programName: programBudgetInfo.programName,
				isInFixedPriceProgram: programBudgetInfo.isInFixedPriceProgram,
				messageTranslationTag: 'program.retainer.not_permitted_modal_message',
			});
			trackEvent('Retainer Add Periods Button', 'Clicked', {error: 'Not permitted'});
			return;
		} else if (
			(programBudgetInfo.isProgramRevenueLocked || programBudgetInfo.revenueSetting === CAP_SETTING.ALWAYS_DISABLED) &&
			capSettingCondition
		) {
			showModal({
				type: MODAL_TYPE.NOT_PERMITTED_PROJECT_BUDGET_CHANGE,
				programName: programBudgetInfo.programName,
				isInFixedPriceProgram: programBudgetInfo.isInFixedPriceProgram,
				messageTranslationTag: 'program.retainer.revenue_locked_error_message',
			});
			trackEvent('Retainer Add Periods Button', 'Clicked', {error: 'Revenue Locked'});
			return;
		} else {
			trackEvent('Retainer Add Periods Button', 'Clicked');
		}

		const projectStartDate = Moment({
			year: project.projectStartYear,
			month: project.projectStartMonth - 1,
			date: project.projectStartDay,
		});
		const today = Moment();
		const firstRetainerPeriodStartDate = firstRetainerPeriod
			? Moment()
					.set('year', firstRetainerPeriod.node.startYear)
					.set('month', firstRetainerPeriod.node.startMonth - 1)
					.set('date', firstRetainerPeriod.node.startDay)
			: projectStartDate.isBefore(today)
			? today
			: projectStartDate;
		const latestRetainerPeriodEndDate = latestRetainerPeriod
			? Moment()
					.set('year', latestRetainerPeriod.node.endYear)
					.set('month', latestRetainerPeriod.node.endMonth - 1)
					.set('date', latestRetainerPeriod.node.endDay)
			: firstRetainerPeriodStartDate.clone().subtract(1, 'days');

		const handleBulkCreatePeriods = preCreateAmount => {
			if (preCreateAmount > 0) {
				const onSuccess = () => {
					dispatch(EVENT_ID.RETAINER_UPDATE_PROJECT);
					// Not the most beautiful approach but range add does not work for bulk creation.
					if (!hasFinancialServiceFlag) {
						retry();
					}
					createToast({duration: 5000, message: formatMessage({id: 'retainer.period-created'})});
					trackEvent('Retainer Periods', 'Created', {amount: preCreateAmount});

					// Update end-date of project
					const newProjectEndDate = getLastPeriodEndDateForSelectedPeriodCount(
						latestRetainerPeriodEndDate,
						preCreateAmount,
						project.defaultPeriodLength,
						project.defaultPeriodPeriodicity
					);
					const projectEndDate = Moment({
						year: project.projectEndYear,
						month: project.projectEndMonth - 1,
						date: project.projectEndDay,
					});
					if (!newProjectEndDate.isBefore(projectEndDate)) {
						const projectMutationObject = {
							project: project,
							projectEndDay: newProjectEndDate.date(),
							projectEndMonth: newProjectEndDate.month() + 1,
							projectEndYear: newProjectEndDate.year(),
						};
						Util.CommitMutation(UpdateProjectMutation, projectMutationObject);
					}
				};
				let startDate;

				// If first period
				if (project.retainerPeriods.edges.length === 0) {
					const projectStart = Moment().set({
						year: project.projectStartYear,
						month: project.projectStartMonth - 1,
						date: project.projectStartDay,
					});
					const today = Moment().startOf('day');
					startDate = projectStart.isBefore(today) ? today : projectStart;
				} else {
					const afterLastRetainerPeriod = Moment()
						.set('year', latestRetainerPeriod.node.endYear)
						.set('month', latestRetainerPeriod.node.endMonth - 1)
						.set('date', latestRetainerPeriod.node.endDay + 1);
					startDate = afterLastRetainerPeriod;
				}

				//const formattedDeadline = Util.GetYearMonthDateFromMomentDate(deadline);
				const mutationObject = {
					name: formatMessage({id: 'retainer.new_period'}),
					projectId: project.id,
					startDay: startDate.date(),
					startMonth: startDate.month() + 1,
					startYear: startDate.year(),
					preCreateAmount: +preCreateAmount,
				};
				Util.CommitMutation(CreateRetainerPeriodMutation, mutationObject, onSuccess);
			}
		};

		const modalData = {
			type: MODAL_TYPE.ADD_PERIODS,
			handleConfirm: handleBulkCreatePeriods,
			defaultPeriodLength: project.defaultPeriodLength,
			defaultPeriodPeriodicity: project.defaultPeriodPeriodicity,
			defaultPeriodBudgetType: project.defaultPeriodBudgetType,
			defaultPeriodHoursAmount: project.defaultPeriodHoursAmount,
			defaultPeriodPriceAmount: project.defaultPeriodPriceAmount,
			existingValue: existingValue,
			currency: currency,
			firstPeriodStartDate: firstRetainerPeriodStartDate,
			latestPeriodEndDate: latestRetainerPeriodEndDate,
			projectId: project.id,
			actualPersonId: viewer.actualPersonId,
			getRetainerPeriodProgramBudgetInfo,
			refetch: () => updateProject(),
			project: project,
		};
		if (isProgramValidationDataReady) {
			showModal(modalData);
		} else {
			setActionOnValidationReady(() => showModal(modalData));
		}
	};

	const goToOverview = () => {
		history.push(projectUrl(viewer.project.companyProjectId, viewer.project.customProjectId) + '/overview?set_dates=true');
	};

	const getAvailableForPeriod = period => {
		const today = Moment();
		const periodEndDate = Util.CreateMomentDate(period.endYear, period.endMonth, period.endDay);
		if (today.isAfter(periodEndDate, 'day')) {
			return 0;
		} else {
			return period.available / 60;
		}
	};

	const getWorkedHoursForPeriod = period => {
		const filteredTimeRegs = timeRegsByPeriodId.get(period.id).nonConflictTimeRegs;
		return filteredTimeRegs.reduce((acc, tReg) => acc + tReg.node.billableMinutesRegistered / 60, 0);
	};

	const getWorkedPriceForPeriod = period => {
		const filteredTimeRegs = timeRegsByPeriodId.get(period.id).nonConflictTimeRegs;
		return filteredTimeRegs.reduce((acc, tReg) => acc + tReg.node.price, 0);
	};

	const getForecastForPeriod = periodPhases => {
		return periodPhases ? periodPhases.reduce((acc, phase) => acc + phase.forecast / 60, 0) : 0;
	};

	const getEstimatedForecastPriceForPeriod = periodPhases => {
		return periodPhases ? periodPhases.reduce((acc, phase) => acc + phase.estimateForecastPrice, 0) : 0;
	};

	const getRemainingForPeriod = periodPhases => {
		return periodPhases ? periodPhases.reduce((acc, phase) => acc + phase.remaining / 60, 0) : 0;
	};

	const getRemainingPriceForPeriod = periodPhases => {
		return periodPhases ? periodPhases.reduce((acc, phase) => acc + phase.remainingPrice, 0) : 0;
	};

	const handleSelectedPeriodRangeChanged = period => {
		Util.localStorageSetItem('RETAINER_SELECTED_TAB', period);
		setSelectedPeriods([]);
		setAllSelected(false);
		setSelectedPeriodRange(period);
	};

	const handleSelectedChanged = (selected, periodId) => {
		const isSelected = selected.target.checked;
		if (!isSelected) {
			setAllSelected(false);
		}
		if (isSelected) {
			setSelectedPeriods([
				...selectedPeriods,
				project.retainerPeriods.edges.find(period => period.node.id === periodId).node,
			]);
		} else {
			setSelectedPeriods(selectedPeriods.filter(period => period.id !== periodId));
		}
	};

	const handleSelectAllChanged = selected => {
		const isSelected = selected.target.checked;
		setAllSelected(isSelected);
		if (isSelected) {
			setSelectedPeriods(project.retainerPeriods.edges.filter(filterByActiveSelection).map(period => period.node));
		} else {
			setSelectedPeriods([]);
		}
	};

	const onPeriodsChanged = () => {
		if (programBudgetInfo.isInFixedPriceProgram || programBudgetInfo.isInCappedRevenueProgram) {
			triggerFinancialServiceCalculationAndWait(viewer.project.id).then(() => {
				refetch(programBudgetInfo.projectId);
			});
		}
	};
	const resetSelectedPeriods = () => {
		setSelectedPeriods([]);
		setAllSelected(false);
		onPeriodsChanged();
	};

	const exportCSV = () => {
		const {formatNumber, formatDate} = intl;
		const project = viewer.project;

		const sortedPeriods = cloneDeep(project.retainerPeriods.edges)
			.sort(sortByStartDate)
			.map(period => period.node);
		const inHours =
			project.defaultPeriodBudgetType === PERIOD_BUDGET_TYPE.FIXED_HOURS ||
			project.defaultPeriodBudgetType === PERIOD_BUDGET_TYPE.TIME_AND_MATERIALS;
		const format = inHours ? 'rounded_two_decimal' : 'always_two_decimal';
		let data = [];

		sortedPeriods.forEach(period => {
			const nonConflictedTimeEntries = timeRegsByPeriodId.get(period.id).nonConflictTimeRegs;
			const totalTime = nonConflictedTimeEntries.reduce(
				(acc, timeReg) => acc + timeReg.node.billableMinutesRegistered / 60,
				0
			);
			const totalPrice = nonConflictedTimeEntries.reduce((acc, timeReg) => acc + timeReg.node.price, 0);

			const dateString =
				(period.startYear
					? formatDate(Util.CreateNonUtcMomentDate(period.startYear, period.startMonth, period.startDay), {
							year: 'numeric',
							month: 'short',
							day: 'numeric',
					  })
					: '') +
				' - ' +
				(period.endYear
					? formatDate(Util.CreateNonUtcMomentDate(period.endYear, period.endMonth, period.endDay), {
							year: 'numeric',
							month: 'short',
							day: 'numeric',
					  })
					: '');
			const financialNumbers = period.financialNumbers;
			const periodTarget =
				hasFinancialServiceFlag && hasRolloverFlag
					? inHours
						? financialNumbers.retainerPeriodTargetMinutes
						: financialNumbers.retainerPeriodTargetPrice
					: inHours
					? period.periodHoursAmount * 60
					: period.periodPriceAmount;
			const periodDifference =
				hasFinancialServiceFlag && hasRolloverFlag
					? inHours
						? financialNumbers.retainerPeriodRolloverMinutes
						: financialNumbers.retainerPeriodRolloverPrice
					: inHours
					? period.periodDifferenceHoursAmount * 60 + period.sharedPeriodDifferenceHoursAmount * 60
					: period.periodDifferencePriceAmount + period.sharedPeriodDifferencePriceAmount;
			const periodTargetTotal = periodTarget + periodDifference;
			const totalValue = inHours ? totalTime * 60 : totalPrice;
			const remainingValue = periodTargetTotal - totalValue;
			const ignoredRollover = inHours ? period.ignoredRolloverHours * 60 : period.ignoredRolloverPrice;
			const progress =
				periodTargetTotal <= 0
					? 100
					: inHours
					? Math.round((totalTime / periodTargetTotal) * 100 * 10) / 10
					: Math.round((totalPrice / periodTargetTotal) * 100 * 10) / 10;
			const d = {
				'period-name': period.name,
				'period-dates': dateString,
				'period-progress': formatNumber(progress) + '%',
				'period-target': formatNumber(periodTarget, {format: format}),
				'period-rollover': formatNumber(periodDifference, {format: format}),
				'period-target-total': formatNumber(periodTargetTotal, {format: format}),
				'period-used-value': formatNumber(totalValue, {format: format}),
				'period-remaining-value': formatNumber(remainingValue, {format: format}),
				'period-locked': period.periodLocked ? 'locked' : '',
				'period-rollover-absorbed': period.periodLocked ? formatNumber(ignoredRollover, {format: format}) : '',
			};

			data.push(d);
		});

		const colNameSuffix = inHours ? '(min)' : `(${Util.GetCurrencySymbol(currency)})`;

		const columnNames = [
			'period-name',
			'period-dates',
			'period-progress',
			'period-target ' + colNameSuffix,
			'period-rollover ' + colNameSuffix,
			'period-target-total ' + colNameSuffix,
			'period-used-value ' + colNameSuffix,
			'period-remaining-value ' + colNameSuffix,
			'period-locked',
			'period-rollover-absorbed ' + colNameSuffix,
		];
		let exportData = columnNames + '\r\n';
		exportData += Util.JSONToCSV(data);
		const fileName = project.name.replace(' ', '_').toLowerCase() + '_retainer_period_data.csv';
		Util.exportToCSV(exportData, fileName);
	};

	const getHeader = (leftContent, rightContent) => <HeaderBar leftContent={leftContent} rightContent={rightContent} />;

	if (!Util.AuthorizeViewerAccess('project-periods')) {
		return <div></div>;
	}
	const phases = project.phases.edges.map(phase => phase.node);
	const allRetainerPeriods = cloneDeep(project.retainerPeriods.edges); // For totals, untouched by filters
	const inHours =
		project.defaultPeriodBudgetType === PERIOD_BUDGET_TYPE.FIXED_HOURS ||
		project.defaultPeriodBudgetType === PERIOD_BUDGET_TYPE.TIME_AND_MATERIALS;
	const isTimeMaterial = project.defaultPeriodBudgetType === PERIOD_BUDGET_TYPE.TIME_AND_MATERIALS;
	const hasTarget =
		!isTimeMaterial ||
		!!project.defaultPeriodHoursAmount ||
		allRetainerPeriods.some(period => !!period.node.periodHoursAmount);
	const isFixedPrice =
		project.defaultPeriodBudgetType === PERIOD_BUDGET_TYPE.FIXED_PRICE &&
		hasFeatureFlag('revenue_recognition_with_all_budget_types', viewer.availableFeatureFlags);
	const {formatMessage, formatNumber} = intl;
	const projectLocked = viewer.project.status === 'DONE' || viewer.project.status === 'HALTED';
	const useTimeApproval = usingTimeApproval(viewer.company.useTimeApproval);
	const {estimationUnit} = project;
	const isEstimatedInPoints = estimationUnit !== 'HOURS';

	const withSuffix = (val, asText) => {
		return asText ? (
			inHours ? (
				Util.convertMinutesToFullHour(val * 60, intl)
			) : (
				Util.getFormattedCurrencyValue(
					Util.GetCurrencySymbol(currency),
					formatNumber(val, {format: 'always_two_decimal'})
				)
			)
		) : (
			<span className={'suffixed-text' + (!inHours ? ' price' : '')}>
				{inHours
					? Util.convertMinutesToFullHour(val * 60, intl)
					: Util.getFormattedCurrencyValue(
							Util.GetCurrencySymbol(currency),
							formatNumber(val, {format: 'always_two_decimal'})
					  )}
			</span>
		);
	};

	const rightContent = [];
	const leftContent = [];
	const hasFinancialAccess = hasSomePermission([
		PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION,
		PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION_REVENUE,
	]);
	const projectStart = Util.CreateMomentDate(project.projectStartYear, project.projectStartMonth, project.projectStartDay);
	const projectEnd = Util.CreateMomentDate(project.projectEndYear, project.projectEndMonth, project.projectEndDay);

	const sortedPeriods = allRetainerPeriods.sort(sortByStartDate);
	const firstRetainerPeriod = sortedPeriods[0];
	const latestRetainerPeriod = sortedPeriods[sortedPeriods.length - 1];
	const periodStart = firstRetainerPeriod
		? Util.CreateMomentDate(
				firstRetainerPeriod.node.startYear,
				firstRetainerPeriod.node.startMonth,
				firstRetainerPeriod.node.startDay
		  )
		: projectStart;
	const periodEnd = latestRetainerPeriod
		? Util.CreateMomentDate(
				latestRetainerPeriod.node.endYear,
				latestRetainerPeriod.node.endMonth,
				latestRetainerPeriod.node.endDay
		  )
		: projectEnd;

	const onboardingFlows = [
		{
			id: 'retainer-tracking-introduction',
			title: 'Introduction to page',
			description: null,
			contentId: '1682508358pEjb3862',
		},
	];

	const onboardingComponent = {
		id: 'onboarding-component',
		intl: intl,
		type: ELEMENT_TYPE.ONBOARDING_POPUP,
		title: intl.formatMessage({id: 'onboarding.retainer_tracking_onboarding_title'}),
		options: onboardingFlows,
		helpCenterLink: 'https://support.forecast.app/hc/en-us/categories/4418778811281-Financial-Management',
		subLink: 'https://support.forecast.app/hc/en-us/articles/5043599309457-Overview-of-Retainer-Budget-Type-',
	};

	leftContent.push(onboardingComponent);

	const csv = {
		type: ELEMENT_TYPE.CSV,
		callback: exportCSV,
		style: BUTTON_STYLE.OUTLINE,
		color: BUTTON_COLOR.LIGHTGREY,
		text: formatMessage({id: 'common.export-csv'}),
		tooltipEnabled: true,
		tooltipProps: {
			autoPlace: true,
			grey: true,
			infoText: formatMessage({id: 'common.export-csv'}),
		},
	};
	leftContent.push(csv);

	const addNew = {
		type: ELEMENT_TYPE.BUTTON,
		id: 'NEW_RETAINER',
		text: formatMessage({id: 'retainer.new_periods'}),
		callback: addPeriod,
		disabled: projectLocked || !projectStart,
		style: BUTTON_STYLE.OUTLINE,
		color: BUTTON_COLOR.PURPLE,
		dataCy: 'retainer-new-period-btn',
	};
	rightContent.push(addNew);

	const isUsingProjectAllocations = viewer.company.isUsingProjectAllocation || viewer.company.isUsingMixedAllocation;
	const showRemainingCapacity = inHours && isUsingProjectAllocations && !project.isInProjectGroup;

	// BEGIN chart stuff
	const {gradientStroke, gradientFill, gradientStrokeFixedPrice} = getGradients();

	let data;
	data = {
		labels: sortedPeriods.map(period => truncate(period.node.name, {length: 15})),
		datasets: [],
	};

	if (hasTarget) {
		data.datasets.push(
			// Target Line
			{
				type: 'line',
				borderColor: gradientStrokeFixedPrice,
				pointHoverRadius: 0,
				pointHoverBorderWidth: 2,
				pointBackgroundColor: 'transparent',
				pointHoverBorderColor: gradientStrokeFixedPrice,
				pointRadius: 0,
				fill: false,
				backgroundColor: '#fff',
				borderWidth: 2,
				label: formatMessage({id: 'retainer.default_period_target'}),
				data: Array(Math.max(2, sortedPeriods.length)).fill(
					inHours ? viewer.project.defaultPeriodHoursAmount : viewer.project.defaultPeriodPriceAmount
				),
			},
			// Retainer Target
			{
				label: formatMessage({id: 'retainer.period_target'}),
				borderColor: gradientStroke,
				backgroundColor: gradientFill,
				lineTension: 0,
				fill: true,
				borderWidth: 1,
				data: sortedPeriods.map(period => {
					const financialNumbers = period.node.financialNumbers;
					if (inHours) {
						const periodDifferenceHoursAmount =
							hasFinancialServiceFlag && hasRolloverFlag
								? financialNumbers.retainerPeriodRolloverMinutes / 60
								: period.node.periodDifferenceHoursAmount + period.node.sharedPeriodDifferenceHoursAmount;
						const periodHoursAmount =
							hasFinancialServiceFlag && hasRolloverFlag
								? financialNumbers.retainerPeriodTargetMinutes / 60
								: period.node.periodHoursAmount;
						return periodDifferenceHoursAmount < 0
							? periodHoursAmount - Math.abs(periodDifferenceHoursAmount)
							: periodHoursAmount;
					} else {
						const periodDifferencePriceAmount =
							hasFinancialServiceFlag && hasRolloverFlag
								? financialNumbers.retainerPeriodRolloverPrice
								: period.node.periodDifferencePriceAmount + period.node.sharedPeriodDifferencePriceAmount;
						const periodPriceAmount =
							hasFinancialServiceFlag && hasRolloverFlag
								? financialNumbers.retainerPeriodTargetPrice
								: period.node.periodPriceAmount;
						return periodDifferencePriceAmount < 0
							? periodPriceAmount - Math.abs(periodDifferencePriceAmount)
							: periodPriceAmount;
					}
				}),
				stack: 1,
			}
		);
	}
	if (!isTimeMaterial) {
		data.datasets.push(
			// Negative Rollover/Subtraction
			{
				label: inHours
					? formatMessage({id: 'retainer.subtracted_hours'})
					: formatMessage({id: 'retainer.subtracted_price'}),
				lineTension: 0,
				fill: true,
				borderWidth: 1,
				borderColor: '#e6e6e6',
				data: sortedPeriods.map(period => {
					if (inHours) {
						const periodDifferenceHoursAmount =
							hasFinancialServiceFlag && hasRolloverFlag
								? period.node.financialNumbers.retainerPeriodRolloverMinutes / 60
								: period.node.periodDifferenceHoursAmount + period.node.sharedPeriodDifferenceHoursAmount;
						return periodDifferenceHoursAmount < 0 ? periodDifferenceHoursAmount : 0;
					} else {
						const periodDifferencePriceAmount =
							hasFinancialServiceFlag && hasRolloverFlag
								? period.node.financialNumbers.retainerPeriodRolloverPrice
								: period.node.periodDifferencePriceAmount + period.node.sharedPeriodDifferencePriceAmount;
						return periodDifferencePriceAmount < 0 ? periodDifferencePriceAmount : 0;
					}
				}),
				stack: 1,
			},
			// Positive Rollover
			{
				label: inHours
					? formatMessage({id: 'retainer.rollover_hours'})
					: formatMessage({id: 'retainer.rollover_price'}),
				lineTension: 0,
				fill: true,
				borderWidth: 1,
				borderColor: '#a1a1a1',
				data: sortedPeriods.map(period => {
					if (inHours) {
						const periodDifferenceHoursAmount =
							hasFinancialServiceFlag && hasRolloverFlag
								? period.node.financialNumbers.retainerPeriodRolloverMinutes / 60
								: period.node.periodDifferenceHoursAmount + period.node.sharedPeriodDifferenceHoursAmount;
						return periodDifferenceHoursAmount > 0 ? periodDifferenceHoursAmount : 0;
					} else {
						const periodDifferencePriceAmount = hasFinancialServiceFlag
							? period.node.financialNumbers.retainerPeriodRolloverPrice
							: period.node.periodDifferencePriceAmount + period.node.sharedPeriodDifferencePriceAmount;
						return periodDifferencePriceAmount > 0 ? periodDifferencePriceAmount : 0;
					}
				}),
				stack: 1,
			}
		);
	}
	if (!isEstimatedInPoints) {
		data.datasets.push(
			// Planned value
			{
				label: inHours
					? formatMessage({id: 'retainer.planned_time'})
					: formatMessage({id: isFixedPrice ? 'retainer.planned_tm_price' : 'retainer.planned_price'}),
				backgroundColor: '#e6e6e6',
				borderColor: '#49d156',
				borderWidth: 1,
				lineTension: 0,
				fill: true,
				data: hasFinancialServiceFlag
					? sortedPeriods.map(
							({
								node: {
									financialNumbers: {billablePlannedTimeAndExpenses, scopeApprovedMinutes},
								},
							}) => (inHours ? scopeApprovedMinutes / 60 : billablePlannedTimeAndExpenses)
					  )
					: sortedPeriods.map(period => {
							const periodPhases = Util.getPhasesForPeriod(period.node, phases);
							return inHours
								? getForecastForPeriod(periodPhases)
								: getEstimatedForecastPriceForPeriod(periodPhases);
					  }),
				stack: 2,
			}
		);
	}
	data.datasets.push(
		// Time entries value - should maybe be separated into has Conflict and no Conflict?
		{
			label: inHours
				? formatMessage({id: 'common.time_entries'})
				: formatMessage({id: 'project_budget.actual_billable_time'}),
			backgroundColor: '#b7ffbe',
			borderColor: '#49d156',
			borderWidth: 1,
			lineTension: 0,
			fill: true,
			data: hasFinancialServiceFlag
				? sortedPeriods.map(
						({
							node: {
								financialNumbers: {billableActualMinutes, billableActualTimeAndExpenses},
							},
						}) => (inHours ? billableActualMinutes / 60 : billableActualTimeAndExpenses)
				  )
				: sortedPeriods.map(period =>
						inHours ? getWorkedHoursForPeriod(period.node) : getWorkedPriceForPeriod(period.node)
				  ),
			stack: 3,
		}
	);

	if (showRemainingCapacity) {
		data.datasets.push(
			// Remaining Capacity
			{
				label: formatMessage({id: 'project_sprints.capacity-remaining-short'}),
				backgroundColor: '#ffe2ab',
				borderColor: '#fbb124',
				borderWidth: 1,
				lineTension: 0,
				fill: true,
				data: hasFinancialServiceFlag
					? sortedPeriods.map(
							({
								node: {
									futureFinancialNumbers: {allocationMinutes},
								},
							}) => allocationMinutes / 60
					  )
					: sortedPeriods.map(period => getAvailableForPeriod(period.node)),
				stack: 3,
			}
		);
	}

	const chartData = () => {
		if (isTimeMaterial) {
			// Planned value
			if (standardPattern) {
				data.datasets[0].backgroundColor = standardPattern;
			}
			// No rollover in Time & Material
			return data;
		}
		// Negative Rollover
		if (negativePattern) {
			data.datasets[2].backgroundColor = negativePattern;
		}
		// Positive Rollover
		if (positivePattern) {
			data.datasets[3].backgroundColor = positivePattern;
		}
		// Planned value
		if (standardPattern) {
			data.datasets[4].backgroundColor = standardPattern;
		}
		return data;
	};

	const barWidth = 20;
	const options = {
		animation: undefined,
		title: {
			display: false,
		},
		font: {
			family: 'neue-haas-grotesk-text',
		},
		plugins: {
			legend: {
				display: true,
				position: 'bottom',
				labels: {
					boxWidth: 20,
					fontSize: 11,
					padding: 8,
				},
			},
			tooltip: {
				mode: 'index',
				intersect: true,
				backgroundColor: '#fff',
				borderColor: '#e6e6e6',
				borderWidth: 1,
				titleColor: '#535353',
				bodyColor: '#535353',
				padding: 12,
				callbacks: {
					label: ctx => {
						const item = ctx.dataset;
						const dataItem = ctx.parsed;
						if (dataItem.y === 0 || item.label === formatMessage({id: 'retainer.default_period_target'})) {
							return '';
						}
						return item.label + ': ' + withSuffix(parseFloat(dataItem.y), true);
					},
				},
			},
			datalabels: {
				display: false,
			},
		},
		responsive: true,
		maintainAspectRatio: false,
		scales: {
			x: {
				stacked: true,
				beginAtZero: true,
				ticks: {
					padding: 6,
					min: 0,
				},
				barThickness: barWidth,
				minBarLength: 5,
				grid: {
					offsetGridLines: true,
					drawTicks: false,
				},
			},
			y: {
				stacked: true,
				beginAtZero: isTimeMaterial,
				ticks: {
					padding: 10,
					//min: 0
				},
				grid: {
					drawTicks: false,
				},
			},

			yNonStack: {
				stacked: false,
				display: false,
			},
		},
	};
	// END chart stuff

	const selectedPeriodCount = selectedPeriods.length;
	const lockedRetainerPeriods = allRetainerPeriods.filter(period => period.node.periodLocked);
	const retainerPeriods = project.retainerPeriods.edges.filter(filterByActiveSelection).sort(sortByStartDate);
	const isEmpty = project.retainerPeriods.edges.length === 0;
	const periodsWithConflict = [];
	let timeEntryTotal = 0;
	let totalConflicted = 0;
	let totalLockedHours = 0;
	let totalLockedPrice = 0;
	allRetainerPeriods.forEach(period => {
		const periodTimeRegs = timeRegsByPeriodId
			.get(period.node.id)
			.conflictTimeRegs.concat(timeRegsByPeriodId.get(period.node.id).nonConflictTimeRegs);

		timeEntryTotal += periodTimeRegs.reduce(
			(acc, timeReg) => acc + (inHours ? timeReg.node.billableMinutesRegistered / 60 : timeReg.node.price),
			0
		);

		if (timeRegsByPeriodId.get(period.node.id).conflictTimeRegs.length) {
			periodsWithConflict.push(period);
			totalConflicted += timeRegsByPeriodId
				.get(period.node.id)
				.conflictTimeRegs.reduce(
					(total, timeReg) => total + (inHours ? timeReg.node.billableMinutesRegistered / 60 : timeReg.node.price),
					0
				);
		}

		if (period.node.periodLocked) {
			totalLockedHours += timeRegsByPeriodId
				.get(period.node.id)
				.nonConflictTimeRegs.reduce((acc, timeReg) => acc + timeReg.node.billableMinutesRegistered / 60, 0);

			totalLockedPrice = timeRegsByPeriodId
				.get(period.node.id)
				.nonConflictTimeRegs.reduce((acc, timeReg) => acc + timeReg.node.price, 0);
		}
	});
	const avgWorkedHours = lockedRetainerPeriods.length > 0 ? totalLockedHours / lockedRetainerPeriods.length : 0;
	const avgWorkedPrice = lockedRetainerPeriods.length > 0 ? totalLockedPrice / lockedRetainerPeriods.length : 0;
	const financialNumbers = viewer.project.financialNumbers;
	const periodDifference =
		hasFinancialServiceFlag && hasRolloverFlag
			? inHours
				? financialNumbers.retainerPeriodRolloverMinutes / 60
				: financialNumbers.retainerPeriodRolloverPrice
			: 0;
	const projectTotal = hasFinancialServiceFlag
		? (inHours ? financialNumbers.retainerPeriodTargetMinutes / 60 : financialNumbers.retainerPeriodTargetPrice) +
		  periodDifference
		: allRetainerPeriods.reduce(
				(acc, period) => acc + (inHours ? period.node.periodHoursAmount : period.node.periodPriceAmount),
				0
		  );
	if (hasFinancialServiceFlag) {
		timeEntryTotal = inHours ? financialNumbers.billableActualMinutes / 60 : financialNumbers.totalActualRevenueRecognition;
	}

	if (periodsWithConflict.length > 0) {
		const retainerWarningTab = (
			<RetainerPeriodWarningTab
				key={'retainer-warning-tab'}
				currency={currency}
				inHours={inHours}
				timeRegsByPeriodId={timeRegsByPeriodId}
				periodsWithConflict={periodsWithConflict}
				viewer={viewer}
				useTimeApproval={useTimeApproval}
			/>
		);
		leftContent.push(retainerWarningTab);
	}

	if (projectLocked) {
		const indicator = {
			type: ELEMENT_TYPE.INDICATOR,
			status: viewer.project.status,
		};
		leftContent.push(indicator);
	}

	const getShouldRetainerStartExpanded = (period, timeRegsForPeriod) => {
		const allTimeRegs = timeRegsForPeriod.conflictTimeRegs.concat(timeRegsForPeriod.nonConflictTimeRegs);
		if (period.periodLocked) {
			return false;
		}
		if (allTimeRegs.length === 0) {
			return false;
		}
		return true;
	};

	const lockableSelectedPeriods = selectedPeriods.filter(period => !period.periodLocked);
	const unlockableSelectedPeriods = selectedPeriods.filter(period => period.periodLocked);
	const changeableSelectedPeriods = selectedPeriods.filter(period => !period.periodLocked);
	const uninvoicedSelectedPeriods = selectedPeriods.filter(period => !period.invoiced);
	const deletableSelectedPeriods = uninvoicedSelectedPeriods;

	const bulkOptions = [
		{
			id: 'lock',
			icon: () => <Icon icon={'lockClosed'} size={'m'} />,
			label: formatMessage({id: useTimeApproval ? 'retainer.approve_lock_periods_short' : 'retainer.lock_periods_short'}),
			callback: () =>
				bulkLockPeriod({
					periods: lockableSelectedPeriods,
					project,
					resetSelectedPeriods,
					intl,
				}),
			variant: BUTTON_VARIANT.VERY_LIGHT_GRAY_OUTLINE,
			disabled: !lockableSelectedPeriods.length,
		},
		{
			id: 'unlock',
			icon: () => <Icon icon={'unlock'} size={'m'} />,
			label: formatMessage({id: 'retainer.unlock_periods_short'}),
			callback: () =>
				bulkUnlockPeriod(project, unlockableSelectedPeriods, timeRegsByPeriodId, resetSelectedPeriods, currency, intl),
			variant: BUTTON_VARIANT.VERY_LIGHT_GRAY_OUTLINE,
			disabled: !unlockableSelectedPeriods.length,
		},
		...(hasFeatureFlag('invoice_retainers_up_front')
			? [
					{
						id: 'bulk create invoices',
						icon: () => <Icon icon={'expense'} size={'m'} />,
						label: formatMessage({id: 'invoicing.create_invoices'}),
						callback: () =>
							bulkCreateInvoices({
								periods: uninvoicedSelectedPeriods,
								project,
								currency,
								onStay: resetSelectedPeriods,
							}),
						variant: BUTTON_VARIANT.VERY_LIGHT_GRAY_OUTLINE,
						disabled: !uninvoicedSelectedPeriods.length,
						cy: 'create-bulk-invoices-button',
					},
			  ]
			: []),
		{
			id: 'change period target',
			icon: () => <Icon icon={'edit'} size={'m'} />,
			label: formatMessage({id: 'retainer.change_targets_short'}),
			callback: () =>
				bulkChangePeriodTarget({
					periods: changeableSelectedPeriods,
					project,
					currency,
					programBudgetInfo,
					resetSelectedPeriods,
					intl,
				}),
			variant: BUTTON_VARIANT.VERY_LIGHT_GRAY_OUTLINE,
			disabled: !changeableSelectedPeriods.length,
		},
		{
			id: 'download',
			icon: () => <Icon icon={'download'} size={'m'} />,
			label: formatMessage({id: 'retainer.download_reported_time'}),
			callback: () => bulkCSVDownload(selectedPeriods, timeRegsByPeriodId, currency, intl),
			variant: BUTTON_VARIANT.VERY_LIGHT_GRAY_OUTLINE,
		},
		{
			id: 'delete',
			icon: () => <Icon icon={'trash'} size={'m'} />,
			label: formatMessage({id: 'common.delete'}),
			callback: () => bulkDeletePeriod(deletableSelectedPeriods, project, resetSelectedPeriods, currency, intl, retry),
			variant: BUTTON_VARIANT.VERY_LIGHT_GRAY_OUTLINE,
			disabled: !deletableSelectedPeriods.length,
		},
	];

	return (
		<LoadMore
			key="financial-calculation-trigger"
			query={financialCalculationTriggerQuery}
			variables={{
				projectId: viewer.project.id,
			}}
			loader={<FinancialCalculationTrigger />}
		>
			{(result, retry) => {
				setFinancialCalculationTriggerTimestamp(result.timestamp);
				handleFinancialCalculationTriggerResponse(retry, result);

				return (
					<div className={'project-retainer-periods'} data-cy={'periods-page'}>
						{children}
						<ProjectHeader
							title={formatMessage({id: 'common.retainer_tracking'})}
							buttons={getHeader(leftContent, rightContent)}
							project={project}
							psProject={viewer.psProject}
						/>
						{isEmpty || !projectStart ? (
							<EmptyState
								pageName={EMPTY_STATE.RETAINER}
								callback={projectStart ? null : () => goToOverview()}
							></EmptyState>
						) : (
							<CustomScrollDiv>
								<div className={`retainer-totals-wrapper`}>
									<div className={'data-visuals'}>
										<CustomScrollDiv horizontalContent width={'unset'}>
											<div className={'chart-and-title'}>
												<div className={'retainer-status-header'}>Retainer Status</div>
												<Bar data={chartData()} options={options}></Bar>
											</div>
										</CustomScrollDiv>
										<div className={'retainer-dashboard-wrapper'}>
											<div className={'retainer-dashboard-header-text'}>
												{formatMessage({id: 'retainer.retainer_totals'})}
											</div>
											<div className={'retainer-dashboard-contents'} data-userpilot="retainer-dashboard">
												<div className={'retainer-type'}>
													<div className={'totals-box-column half-text-wrapper'}>
														<div className={'retainer-total-text'}>
															{formatMessage({id: 'retainer.type'})}
														</div>
													</div>
													<div className={'totals-box-column half-text-wrapper'}>
														<div className={'retainer-total-data'}>
															{Util.getBudgetTypeTranslation(
																project.budgetType,
																intl,
																project.defaultPeriodBudgetType
															)}
														</div>
													</div>
												</div>
												{hasTarget && (
													<div className={'retainer-total-period-length'}>
														<div className={'totals-box-column half-text-wrapper'}>
															<div className={'retainer-total-text'}>
																{formatMessage({id: 'retainer.total_project_target'})}
															</div>
														</div>
														<div className={'totals-box-column half-text-wrapper'}>
															<div className={'retainer-total-data'}>
																{withSuffix(projectTotal)}
															</div>
														</div>
													</div>
												)}
												<div className={'retainer-total-period-work'}>
													<div className={'totals-box-column half-text-wrapper'}>
														<div
															className={'retainer-total-text'}
															title={
																inHours
																	? formatMessage({
																			id: 'retainer.billable_time_entries_in_periods',
																	  })
																	: formatMessage({
																			id: isFixedPrice
																				? 'retainer.total_tm_price_registered'
																				: 'retainer.total_price_registered',
																	  })
															}
														>
															{inHours
																? formatMessage({
																		id: 'retainer.billable_time_entries_in_periods',
																  })
																: formatMessage({
																		id: isFixedPrice
																			? 'retainer.total_tm_price_registered'
																			: 'retainer.total_price_registered',
																  })}
														</div>
													</div>
													<div className={'totals-box-column text-wrapper'}>
														<div className={'retainer-total-data'}>
															{withSuffix(timeEntryTotal)}
														</div>
													</div>
												</div>
												<div className={'retainer-total-dates'}>
													<div className={'totals-box-column wrapper'}>
														<div className={'retainer-total-text'}>
															{formatMessage({id: 'common.dates'})}
														</div>
													</div>
													<div className={'totals-box-column wrapper grow'}>
														<div className={'retainer-total-data'}>
															{(periodStart ? periodStart.format('DD MMM YYYY') : ' ') +
																' - ' +
																(periodEnd ? periodEnd.format('DD MMM YYYY') : '')}
														</div>
													</div>
												</div>
												<div className={'retainer-total-periods-and-progress'}>
													<div className={'retainer-total-periods'}>
														<div className={'totals-box-column retainer-total-text'}>
															{formatMessage({id: 'retainer.periods'})}
														</div>
														<div className={'totals-box-column retainer-total-data'}>
															{project.retainerPeriods.edges.length}
														</div>
													</div>
												</div>
												{hasTarget && (
													<div className={'retainer-total-period-length'}>
														<div className={'totals-box-column half-text-wrapper'}>
															<div className={'retainer-total-text'}>
																{formatMessage({id: 'retainer.default_period_target'})}
															</div>
														</div>
														<div className={'totals-box-column half-text-wrapper'}>
															<div className={'retainer-total-data'}>
																{withSuffix(
																	inHours
																		? project.defaultPeriodHoursAmount
																		: project.defaultPeriodPriceAmount
																)}
															</div>
														</div>
													</div>
												)}

												<div className={'retainer-average-period-work'}>
													<div className={'totals-box-column half-text-wrapper'}>
														<div
															className={'retainer-total-text'}
															title={
																inHours
																	? formatMessage({id: 'retainer.average_hours_per_period'})
																	: formatMessage({id: 'retainer.average_price_per_period'})
															}
														>
															{inHours
																? formatMessage({id: 'retainer.average_hours_per_period'})
																: formatMessage({id: 'retainer.average_price_per_period'})}
														</div>
													</div>
													<div className={'totals-box-column text-wrapper'}>
														<div className={'retainer-total-data'}>
															{withSuffix(inHours ? avgWorkedHours : avgWorkedPrice)}
														</div>
													</div>
												</div>
												{!isTimeMaterial ? (
													<div
														className={
															'retainer-time-entry-conflicts' + (!totalConflicted ? ' none' : '')
														}
													>
														<div className={'totals-box-column half-text-wrapper'}>
															<div className={'retainer-total-text'}>
																{inHours
																	? formatMessage({id: 'retainer.conflicted_time'})
																	: formatMessage({id: 'retainer.conflicted_price'})}
															</div>
														</div>
														<div className={'totals-box-column half-text-wrapper'}>
															<div
																data-cy={'dashboard-time-entry-conflict-text'}
																className={'retainer-total-data'}
															>
																{withSuffix(totalConflicted)}
															</div>
														</div>
													</div>
												) : null}
											</div>
										</div>
									</div>
								</div>
								<div className={`retainer-periods-selector`}>
									<Button
										colorTheme={
											selectedPeriodRange === 'ALL' ? BUTTON_COLOR.PURPLE : BUTTON_COLOR.MEDIUMGREY
										}
										buttonStyle={BUTTON_STYLE.FILLED}
										text={formatMessage({id: 'retainer.tab.all'})}
										className={'retainer-period-selector-btn'}
										onClick={() => handleSelectedPeriodRangeChanged('ALL')}
									/>
									<Button
										colorTheme={
											selectedPeriodRange === 'PAST' ? BUTTON_COLOR.PURPLE : BUTTON_COLOR.MEDIUMGREY
										}
										buttonStyle={BUTTON_STYLE.FILLED}
										text={formatMessage({id: 'retainer.tab.past'})}
										className={'retainer-period-selector-btn'}
										onClick={() => handleSelectedPeriodRangeChanged('PAST')}
									/>
									<Button
										colorTheme={
											selectedPeriodRange === 'CURRENT' ? BUTTON_COLOR.PURPLE : BUTTON_COLOR.MEDIUMGREY
										}
										buttonStyle={BUTTON_STYLE.FILLED}
										text={formatMessage({id: 'retainer.tab.current'})}
										className={'retainer-period-selector-btn'}
										onClick={() => handleSelectedPeriodRangeChanged('CURRENT')}
									/>
									<Button
										colorTheme={
											selectedPeriodRange === 'FUTURE' ? BUTTON_COLOR.PURPLE : BUTTON_COLOR.MEDIUMGREY
										}
										buttonStyle={BUTTON_STYLE.FILLED}
										text={formatMessage({id: 'retainer.tab.future'})}
										className={'retainer-period-selector-btn'}
										onClick={() => handleSelectedPeriodRangeChanged('FUTURE')}
									/>
								</div>
								<div className={`retainer-period-column-header`}>
									<div className="period-select-checkbox">
										<Checkbox
											cy={'select-all-periods-checkbox'}
											isChecked={isAllSelected}
											onClick={selected => handleSelectAllChanged(selected)}
										/>
									</div>
									<div className={'retainer-period-column-name'}>
										<div className={'period-name'}> {formatMessage({id: 'common.period'})}</div>
									</div>
									<div className={'retainer-period-column-name dates center'}>
										{formatMessage({id: 'common.dates'})}
									</div>
									{hasTarget && (
										<div className={'retainer-period-column-name right'}>
											{formatMessage({id: 'common.progress'})}
										</div>
									)}
									{
										<div className={'retainer-period-column-name right'}>
											{formatMessage({id: 'common.period_type'})}
										</div>
									}
									{hasTarget && (
										<div className={'retainer-period-column-name right'}>
											{formatMessage({id: 'retainer.period_target'})}
										</div>
									)}
									{!isEstimatedInPoints && (
										<>
											<div className={'retainer-period-column-name right'}>
												{formatMessage({
													id: isFixedPrice
														? hasFinancialServiceFlag
															? 'retainer.tm_planned_time'
															: 'retainer.tm_period_plan'
														: 'retainer.period_plan',
												})}
											</div>
											<div className={'retainer-period-column-name right'}>
												{formatMessage({
													id: isFixedPrice
														? hasFinancialServiceFlag
															? 'retainer.remaining_tm_time'
															: 'retainer.remaining_tm_period_plan'
														: 'retainer.remaining_period_plan',
												})}
											</div>
										</>
									)}
									{showRemainingCapacity && inHours ? (
										<div className={'retainer-period-column-name right'}>
											{formatMessage({id: 'project_sprints.capacity-remaining-short'})}
										</div>
									) : null}
									<div className={'retainer-period-column-name right'}>
										{inHours
											? formatMessage({id: 'common.time_entries'})
											: formatMessage({
													id: 'project_budget.actual_billable_time',
											  })}
									</div>
									<div className={'retainer-period-column-name right'}>
										{formatMessage({id: 'common.forecast'})}
									</div>
								</div>
								<div className={`retainer-periods-container`}>
									{retainerPeriods.length === 0 ? (
										<div className={'retainer-period-wrapper empty'} key={'empty-wrapper'}>
											<div className={'empty-periods-text'}>
												{formatMessage({
													id: 'retainer.no_' + selectedPeriodRange.toLocaleLowerCase() + '_periods',
												})}
											</div>
										</div>
									) : (
										retainerPeriods.map((period, index) => {
											const financialNumbers = period.node.financialNumbers;
											const futureFinancialNumbers = period.node.futureFinancialNumbers;
											const userpilotIndex =
												index === 0 ? 'first' : index === retainerPeriods.length - 1 ? 'last' : null;

											const periodPhases = Util.getPhasesForPeriod(period.node, phases);

											const timeRegsForPeriod = timeRegsByPeriodId.get(period.node.id);
											const phasesForPeriod = Util.getPhasesForPeriod(period.node, phases);
											const planForPeriod = inHours
												? hasFinancialServiceFlag
													? financialNumbers.scopeApprovedMinutes / 60
													: getForecastForPeriod(periodPhases)
												: hasFinancialServiceFlag
												? financialNumbers.billablePlannedTimeAndExpenses
												: getEstimatedForecastPriceForPeriod(periodPhases);
											const remainingPlanForPeriod = inHours
												? hasFinancialServiceFlag
													? financialNumbers.remainingMinutes / 60
													: getRemainingForPeriod(periodPhases)
												: hasFinancialServiceFlag
												? financialNumbers.billableForecastTimeAndExpensesToComplete
												: getRemainingPriceForPeriod(periodPhases);
											const availableForPeriod = inHours
												? hasFinancialServiceFlag
													? futureFinancialNumbers.allocationMinutes / 60
													: getAvailableForPeriod(period.node)
												: 0;
											return (
												<div
													className={'retainer-period-wrapper'}
													key={period.node.id + '-wrapper'}
													data-cy={'retainer-period-wrapper'}
												>
													<RetainerPeriod
														currency={currency}
														key={period.node.id}
														userpilotIndex={userpilotIndex}
														viewer={viewer}
														project={project}
														hasFinancialAccess={hasFinancialAccess}
														startExpanded={getShouldRetainerStartExpanded(
															period.node,
															timeRegsForPeriod
														)}
														timeRegs={timeRegsForPeriod}
														period={period}
														phasesForPeriod={phasesForPeriod}
														planForPeriod={planForPeriod}
														remainingPlanForPeriod={remainingPlanForPeriod}
														availableForPeriod={availableForPeriod}
														showTaskModal={taskId => Util.showTaskModal(taskId, history)}
														withSuffix={withSuffix}
														inHours={inHours}
														isHarvestProject={viewer.project.harvestProject}
														projectLocked={projectLocked}
														showRemainingCapacity={showRemainingCapacity}
														retry={retry}
														useTimeApproval={useTimeApproval}
														isEstimatedInPoints={isEstimatedInPoints}
														isFixedPrice={isFixedPrice}
														resetSelectedPeriods={resetSelectedPeriods}
														handleSelectedChanged={handleSelectedChanged}
														onPeriodsChanged={onPeriodsChanged}
														isSelected={selectedPeriods.some(
															selectedPeriod => selectedPeriod.id === period.node.id
														)}
														programBudgetInfo={programBudgetInfo}
														sideNavExpanded={sideNavExpanded}
														sideNavLocked={sideNavLocked}
														hasTarget={hasTarget}
													/>
												</div>
											);
										})
									)}
								</div>
							</CustomScrollDiv>
						)}
						{selectedPeriodCount > 0 && (
							<BulkSelectPopup
								itemCount={selectedPeriodCount}
								counterText={formatMessage({id: 'bulk_edit.periods_selected'})}
								actionOptions={bulkOptions}
								onClose={resetSelectedPeriods}
								warningMessage={formatMessage({id: 'bulk_edit.period_warning'})}
								warningIcon={
									<ForecastTooltip
										maxWidth={400}
										content={
											<ul>
												<li>{formatMessage({id: 'bulk_edit.period_warning_tooltip_1'})}</li>
												<li>{formatMessage({id: 'bulk_edit.period_warning_tooltip_2'})}</li>
												{!hasFeatureFlag('rollover_values_on_retainer_periods') && (
													<>
														<li>{formatMessage({id: 'bulk_edit.period_warning_tooltip_3'})}</li>
														<li>{formatMessage({id: 'bulk_edit.period_warning_tooltip_4'})}</li>
													</>
												)}
											</ul>
										}
									>
										<Icon icon={'help'} size={'s'} />
									</ForecastTooltip>
								}
							/>
						)}
					</div>
				);
			}}
		</LoadMore>
	);
};

const projectRetainerPeriodsQuery = graphql`
	query projectRetainerPeriods_Query(
		$projectId: String
		$startYear: Int
		$startMonth: Int
		$startDay: Int
		$hasFinancialServiceFlag: Boolean!
	) {
		viewer {
			actualPersonId
			component(name: "retainer_periods", projectId: $projectId)
			project(id: $projectId) {
				id
			}
			...projectRetainerPeriods_viewer
				@arguments(
					projectId: $projectId
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					hasFinancialServiceFlag: $hasFinancialServiceFlag
				)
		}
	}
`;

export {projectRetainerPeriodsQuery};

export default withRouter(
	createFragmentContainer(projectRetainerPeriods, {
		viewer: graphql`
			fragment projectRetainerPeriods_viewer on Viewer
			@argumentDefinitions(
				projectId: {type: "String"}
				startYear: {type: "Int"}
				startMonth: {type: "Int"}
				startDay: {type: "Int"}
				hasFinancialServiceFlag: {type: "Boolean!"}
			) {
				id
				actualPersonId
				availableFeatureFlags {
					key
				}
				startDate
				endDate
				createdAt
				monday
				tuesday
				wednesday
				thursday
				friday
				saturday
				sunday
				company {
					...genericTaskContextMenu_company
					id
					currency
					userSeats
					virtualSeats
					tier
					isChargebeeCustomer
					currency
					useTimeApproval
					isUsingProjectAllocation
					isUsingMixedAllocation
					modules {
						moduleType
					}
				}
				psProject(companyProjectId: $projectId) {
					...ProjectHeader_psProject
					program
					programBudgetType
					psProgram {
						name
						createdBy {
							person {
								id
							}
						}
						budgetType
						budgetValue
						projectsFinancialsTotals {
							totalRevenueRecognition
						}
					}
				}
				project(id: $projectId) {
					...ProjectHeader_project
					id
					name
					isInProjectGroup
					harvestProject {
						id
					}
					projectGroupId
					companyProjectId
					customProjectId
					status
					projectStartYear
					projectStartMonth
					projectStartDay
					projectEndYear
					projectEndMonth
					projectEndDay
					programBudgetType
					isProgramRevenueLocked
					programRemainingBudget
					programRevenueSetting
					manualProgressOnProjectEnabled
					manualProgressOnPhasesEnabled
					manualProgressOnTasksEnabled
					budgetType
					rateCard {
						currency
					}
					statusColumnsV2(first: 1000000) {
						edges {
							node {
								id
								name
								category
								order
								jiraStatusId
							}
						}
					}
					phases(first: 1000) {
						edges {
							node {
								id
								name
								startYear
								startMonth
								startDay
								deadlineDay
								deadlineMonth
								deadlineYear
								forecast
								estimateForecastPrice
								remaining
								remainingPrice
								phasePersons(first: 10000) {
									edges {
										node {
											id
											availableMinutes
											scheduledMinutes
											person {
												id
											}
										}
									}
								}
							}
						}
					}
					timeRegistrations(first: 10000000) @connection(key: "Project_timeRegistrations", filters: []) {
						edges {
							node {
								id
								day
								month
								year
								createdAt
								updatedAt
								minutesRegistered
								billableMinutesRegistered
								xeroInvoiceId
								lockedInPeriod
								harvestTimeId
								harvestTask {
									id
									name
								}
								invoiced
								price
								retainerConflictHandled
								person {
									id
									firstName
									lastName
									profilePictureId
									profilePictureDefaultId
								}
								task {
									id
									name
									companyTaskId
									deadlineYear
									billable
									approved
									deadlineMonth
									deadlineDay
									startDay
									startYear
									hasChildren
									startMonth
									blocked
									sageIntacctId
									bug
									highPriority
									canStart
									canBeSetToDone
									statusColumnV2 {
										id
										name
									}
								}
								project {
									id
									name
								}
							}
						}
					}
					estimationUnit
					minutesPerEstimationPoint
					projectColor
					projectStartYear
					projectStartMonth
					projectStartDay
					projectEndYear
					projectEndMonth
					projectEndDay
					budgetType
					defaultPeriodPeriodicity
					defaultPeriodLength
					defaultPeriodBudgetType
					defaultPeriodHoursAmount
					defaultPeriodPriceAmount
					defaultPeriodSettingSubtractValue
					defaultPeriodSettingRollValue
					retainerPeriods(first: 100000) @connection(key: "Project_retainerPeriods", filters: []) {
						edges {
							node {
								id
								name
								startYear
								startMonth
								startDay
								endYear
								endMonth
								endDay
								available
								periodLength
								periodPeriodicity
								periodPriceAmount
								periodHoursAmount
								periodBudgetType
								periodSettingIgnoreForBilling
								periodSettingSubtractValue
								periodSettingRollValue
								periodSettingAddExpenses
								periodDifferencePriceAmount
								periodDifferenceHoursAmount
								sharedPeriodDifferenceHoursAmount
								sharedPeriodDifferencePriceAmount
								ignoredRolloverHours
								ignoredRolloverPrice
								periodLocked
								periodLockedTime
								periodDifferenceSplit
								invoiced
								financialNumbers(convertToProjectCurrency: true) @include(if: $hasFinancialServiceFlag) {
									retainerPeriodTargetPrice
									retainerPeriodTargetMinutes
									billablePlannedTimeAndExpenses
									billableForecastTimeAndExpensesToComplete
									billableActualTimeAndExpenses
									billableTotalTimeAndExpensesAtCompletion
									scopeApprovedMinutes
									remainingMinutes
									billableActualMinutes
									totalActualRevenueRecognition
									retainerPeriodRolloverMinutes
									retainerPeriodRolloverPrice
								}
								futureFinancialNumbers: financialNumbers(
									convertToProjectCurrency: true
									startYear: $startYear
									startMonth: $startMonth
									startDay: $startDay
								) @include(if: $hasFinancialServiceFlag) {
									allocationMinutes
								}
								retainerPeriodRollovers(first: 100000) {
									edges {
										node {
											id
											periodFrom {
												id
												name
											}
											periodTo {
												id
												name
												periodLocked
											}
											amount
										}
									}
								}
							}
						}
					}
					financialNumbers(convertToProjectCurrency: true) @include(if: $hasFinancialServiceFlag) {
						retainerPeriodTargetPrice
						retainerPeriodTargetMinutes
						retainerPeriodRolloverPrice
						retainerPeriodRolloverMinutes
						totalActualRevenueRecognition
						billableActualMinutes
					}
				}
			}
		`,
	})
);
