import React from 'react';
import {createRefetchContainer, graphql} from 'react-relay';

import CreateInvoiceModalV2 from './create_invoice_modal_v2';
import {BUDGET_TYPE} from '../../../constants';
import {cloneDeep} from 'lodash';

export const standaloneCreateInvoiceModalV2 = props => {
	const {
		closeModal,
		defaultSelectedProjectIds,
		lockedClientId,
		viewer: {company: origCompany, projects, project},
		startDate,
		endDate,
		refetch,
		onInvoiceCreation,
		clientId,
		projectId,
		relay: {refetch: modalRefetch},
		isProjectInvoicing,
	} = props;

	const company = cloneDeep(origCompany);
	company.exchangeRateMap = company.exchangeRates.edges
		.map(edge => edge.node)
		.reduce((map, exchangeRate) => {
			map[exchangeRate.currency] = exchangeRate.rate;
			return map;
		}, {});

	const filteredProjects = isProjectInvoicing
		? [project]
		: projects.edges.map(edge => edge.node).filter(project => project.budgetType !== BUDGET_TYPE.NON_BILLABLE);

	return (
		<CreateInvoiceModalV2
			closeModal={closeModal}
			defaultSelectedProjectIds={defaultSelectedProjectIds}
			lockedClientId={isProjectInvoicing ? project.client.id : lockedClientId}
			company={company}
			projects={filteredProjects}
			startDate={startDate}
			endDate={endDate}
			refetch={refetch}
			onInvoiceCreation={onInvoiceCreation}
			clientId={clientId}
			projectId={projectId}
			modalRefetch={modalRefetch}
			isProjectInvoicing={isProjectInvoicing}
			project={project}
		/>
	);
};

const standaloneCreateInvoiceModalV2Query = graphql`
	query standaloneCreateInvoiceModalV2_Query(
		$startYear: Int!
		$startMonth: Int!
		$startDay: Int!
		$endYear: Int!
		$endMonth: Int!
		$endDay: Int!
		$isProjectInvoicing: Boolean!
		$projectId: ID
	) {
		viewer {
			actualPersonId
			component(name: "create_invoice_modal_standalone_v2")
			...standaloneCreateInvoiceModalV2_viewer
				@arguments(
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					endYear: $endYear
					endMonth: $endMonth
					endDay: $endDay
					isProjectInvoicing: $isProjectInvoicing
					projectId: $projectId
				)
		}
	}
`;

export {standaloneCreateInvoiceModalV2Query};

export default createRefetchContainer(
	standaloneCreateInvoiceModalV2,
	{
		viewer: graphql`
			fragment standaloneCreateInvoiceModalV2_viewer on Viewer
			@argumentDefinitions(
				startYear: {type: "Int!"}
				startMonth: {type: "Int!"}
				startDay: {type: "Int!"}
				endYear: {type: "Int!"}
				endMonth: {type: "Int!"}
				endDay: {type: "Int!"}
				isProjectInvoicing: {type: "Boolean!"}
				projectId: {type: "ID"}
			) {
				id
				backendId
				firstName
				actualPersonId
				availableFeatureFlags {
					key
				}
				company {
					currency
					invoiceLastSequence
					invoiceQuantityDecimals
					exchangeRates {
						edges {
							node {
								id
								currency
								rate
							}
						}
					}
					modules {
						moduleType
					}
					clients {
						edges {
							node {
								id
								name
							}
						}
					}
					allPersons(first: 1000000, onlyActive: false, excludeClientUsers: false) {
						edges {
							...PersonDropdown_persons
							node {
								id
								firstName
								lastName
								profilePictureId
								profilePictureDefaultId
								role {
									id
									name
								}
							}
						}
					}
				}

				project(internalId: $projectId) @include(if: $isProjectInvoicing) {
					id
					name
					projectColor
					isInProjectGroup
					companyProjectId
					projectGroupId
					currencyExchangeRate
					budgetType
					defaultPeriodBudgetType
					status
					budget
					projectEndYear
					projectEndMonth
					projectEndDay
					fullAccessToProject
					rateCard {
						id
						name
						currency
						parentRateCardId
						defaultRate
						rates(first: 10000) {
							edges {
								node {
									id
									rate
									startDate
									defaultRate
									role {
										id
										name
									}
								}
							}
						}
					}
					client {
						id
						name
					}
					invoiceTotals {
						invoiceId
						createdDay
						createdMonth
						createdYear
						invoicedWithoutTax
						invoicedWithoutTaxBaseCurrency
						invoicedWithoutTaxProjectCurrency
					}
					unInvoicedTotal(
						startYear: $startYear
						startMonth: $startMonth
						startDay: $startDay
						endYear: $endYear
						endMonth: $endMonth
						endDay: $endDay
					) {
						unInvoicedMinutes
						unInvoicedPrice
					}
					expenseItems {
						edges {
							node {
								id
								name
								approved
								expenseYear
								expenseMonth
								expenseDay
								billable
								partOfFixedPrice
								price
								cost
								quantity
								planned
								createdAt
								invoiceId
								invoiced
								person {
									id
									fullName
									profilePictureId
									profilePictureDefaultId
								}
								phase {
									id
									name
								}
							}
						}
					}
					retainerPeriods(first: 100000) {
						edges {
							node {
								id
								name
								periodBudgetType
								startYear
								startMonth
								startDay
								endYear
								endMonth
								endDay
								periodPriceAmount
								periodHoursAmount
								periodBudgetType
								periodLocked
								invoiced
								sharedPeriodDifferencePriceAmount
							}
						}
					}
					...DeprecatedProjectIndicatorJS_project
				}
				projects(excludeDoneOrHalted: true) @skip(if: $isProjectInvoicing) {
					edges {
						...ProjectDropdown_projects
						node {
							id
							name
							projectColor
							isInProjectGroup
							companyProjectId
							projectGroupId
							currencyExchangeRate
							budgetType
							defaultPeriodBudgetType
							status
							budget
							projectEndYear
							projectEndMonth
							projectEndDay
							fullAccessToProject
							invoiceTotals {
								invoiceId
								createdDay
								createdMonth
								createdYear
								invoicedWithoutTax
								invoicedWithoutTaxBaseCurrency
								invoicedWithoutTaxProjectCurrency
							}
							unInvoicedTotal(
								startYear: $startYear
								startMonth: $startMonth
								startDay: $startDay
								endYear: $endYear
								endMonth: $endMonth
								endDay: $endDay
							) {
								unInvoicedMinutes
								unInvoicedPrice
							}
							currentProjectStatus {
								id
								color
							}
							client {
								id
								name
								logoId
								logoDefaultId
							}
							rateCard {
								id
								name
								currency
								parentRateCardId
								defaultRate
								rates(first: 10000) {
									edges {
										node {
											id
											rate
											startDate
											defaultRate
											role {
												id
												name
											}
										}
									}
								}
							}
							expenseItems {
								edges {
									node {
										id
										name
										approved
										expenseYear
										expenseMonth
										expenseDay
										billable
										partOfFixedPrice
										price
										cost
										quantity
										planned
										createdAt
										invoiceId
										invoiced
										person {
											id
											fullName
											profilePictureId
											profilePictureDefaultId
										}
										phase {
											id
											name
										}
									}
								}
							}
							retainerPeriods(first: 100000) {
								edges {
									node {
										id
										name
										periodBudgetType
										startYear
										startMonth
										startDay
										endYear
										endMonth
										endDay
										periodPriceAmount
										periodHoursAmount
										periodBudgetType
										periodLocked
										invoiced
										sharedPeriodDifferencePriceAmount
									}
								}
							}
							...DeprecatedProjectIndicatorJS_project
						}
					}
				}
			}
		`,
	},
	graphql`
		query standaloneCreateInvoiceModalV2RefetchQuery(
			$startYear: Int!
			$startMonth: Int!
			$startDay: Int!
			$endYear: Int!
			$endMonth: Int!
			$endDay: Int!
			$isProjectInvoicing: Boolean!
			$projectId: ID
		) {
			viewer {
				component(name: "create_invoice_modal_standalone_v2")
				...standaloneCreateInvoiceModalV2_viewer
					@arguments(
						startYear: $startYear
						startMonth: $startMonth
						startDay: $startDay
						endYear: $endYear
						endMonth: $endMonth
						endDay: $endDay
						isProjectInvoicing: $isProjectInvoicing
						projectId: $projectId
					)
			}
		}
	`
);
