import { Checkbox, ComboBox, DeprecatedCreativeModal as CreativeModal, DeprecatedScrollbar as Scrollbar, FlexColumn, FlexRow, SearchField, SubHeading, Text, } from '@forecast-it/design-system';
import React, { useEffect, useState, useMemo } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components/macro';
import { useIntl } from 'react-intl';
import { hideLoader, showLoader } from '../../global_loader';
import Util from '../../../forecast-app/shared/util/util';
import CreateSageIntacctTasksMutation from '../../../mutations/CreateSageIntacctTasksMutation';
import DirectApi from '../../../directApi';
import { createToast } from '../../../forecast-app/shared/components/toasts/another-toast/toaster';
import { HorizontalBar } from './SageIntacctModal.styled';
const scrollSectionHeight = (window === null || window === void 0 ? void 0 : window.innerHeight) < 1000 ? '250px' : '500px';
const ListItem = styled.div `
	width: 550px;
	padding: 18px 8px 18px 8px;
	border-bottom: ${({ hasBorderBottom }) => (hasBorderBottom ? '1px solid #ccc' : 'none')};
`;
const ListWrapper = styled.div `
	height: ${scrollSectionHeight};
`;
const SageIntacctExportTasksModal = ({ closeModal, viewer, isPartOfProjectSyncFlow, }) => {
    const { formatMessage } = useIntl();
    const [tasks, setTasks] = useState([]);
    const [filteredTasks, setFilteredTasks] = useState([]);
    const [selectedTasks, setSelectedTasks] = useState([]);
    const selectAll = useMemo(() => {
        if (filteredTasks.length === 0 || selectedTasks.length === 0)
            return false;
        return filteredTasks.every(t => selectedTasks.includes(t.id));
    }, [filteredTasks, selectedTasks]);
    const [items, setItems] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [fetchingItems, setFetchingItems] = useState(false);
    const [exporting, setExporting] = useState(false);
    const [failed, setFailed] = useState(false);
    const disableExportButton = !selectedItem || fetchingItems || exporting || selectedTasks.length === 0;
    const closeModalWithToast = () => {
        var _a;
        if (isPartOfProjectSyncFlow) {
            createToast({
                duration: 5000,
                message: `${(_a = viewer.project) === null || _a === void 0 ? void 0 : _a.name} synced with Sage Intacct`,
            });
        }
        closeModal();
    };
    useEffect(() => {
        var _a, _b;
        if (((_b = (_a = viewer.project) === null || _a === void 0 ? void 0 : _a.tasks) === null || _b === void 0 ? void 0 : _b.edges) && Array.isArray(viewer.project.tasks.edges)) {
            const projectTasks = viewer.project.tasks.edges.map(edge => edge && edge.node).filter(task => !task.sageIntacctId);
            // Close modal if there are no tasks to list and this was as part of the sync flow
            if (projectTasks.length === 0 && isPartOfProjectSyncFlow) {
                closeModalWithToast();
            }
            else {
                setTasks(projectTasks);
                setFilteredTasks(projectTasks);
            }
        }
    }, []);
    useEffect(() => {
        setFetchingItems(true);
        DirectApi.Fetch('sageIntacct/items').then(res => {
            if (res === null || res === void 0 ? void 0 : res.items) {
                setItems(res.items);
                setFetchingItems(false);
            }
            else if ((res === null || res === void 0 ? void 0 : res.error) || (res === null || res === void 0 ? void 0 : res.status) === 500 || (res === null || res === void 0 ? void 0 : res.statusCode) === 500) {
                setFailed(true);
                setFetchingItems(false);
            }
            else {
                setItems([]);
                setFetchingItems(false);
            }
        });
    }, []);
    const selectAllClick = () => {
        if (filteredTasks.length === 0)
            return;
        if (selectedTasks.length === 0) {
            setSelectedTasks(filteredTasks.map(t => t.id));
        }
        else {
            const allFilteredIds = filteredTasks.map(t => t.id);
            const isAllFilteredIdsInSelected = allFilteredIds.every(id => selectedTasks.includes(id));
            if (isAllFilteredIdsInSelected) {
                const removedSelectedIds = selectedTasks.filter(id => !allFilteredIds.includes(id));
                setSelectedTasks(removedSelectedIds);
            }
            else {
                const addedNewSelectedIds = selectedTasks.concat(allFilteredIds);
                setSelectedTasks(addedNewSelectedIds);
            }
        }
    };
    const taskClick = (inputId) => {
        if (selectedTasks.includes(inputId)) {
            const tempTasks = selectedTasks.filter(id => id !== inputId);
            setSelectedTasks(tempTasks);
        }
        else {
            const tempTasks = [...selectedTasks];
            tempTasks.push(inputId);
            setSelectedTasks(tempTasks);
        }
    };
    const onExportClick = () => {
        var _a, _b, _c;
        const sageProjectId = (_b = (_a = viewer.project) === null || _a === void 0 ? void 0 : _a.sageProject) === null || _b === void 0 ? void 0 : _b.sageProjectId;
        const projectId = (_c = viewer.project) === null || _c === void 0 ? void 0 : _c.id;
        setExporting(true);
        showLoader();
        Util.CommitMutation(CreateSageIntacctTasksMutation, {
            sageIntacctProjectId: sageProjectId,
            sageIntacctItemId: selectedItem,
            forecastProjectId: projectId,
            taskIds: selectedTasks,
        }, () => {
            setExporting(false);
            hideLoader();
            closeModalWithToast();
        });
    };
    const onFilterChange = (input) => {
        setFilteredTasks(tasks.filter(task => task.name.toLowerCase().includes(input.toLowerCase())));
    };
    return (React.createElement(CreativeModal, { title: 'Export Tasks to Sage Intacct', size: 'l', closeModal: closeModalWithToast, creativeButtonText: `Export Tasks to Sage Intacct (${selectedTasks.length})`, cancelButtonText: isPartOfProjectSyncFlow ? formatMessage({ id: 'common.skip' }) : formatMessage({ id: 'common.cancel' }), onCreateHandler: onExportClick, onCancelHandler: closeModalWithToast, disabled: disableExportButton, replicateDesignSystem: true },
        React.createElement(ComboBox, { label: "Item", "data-cy": "combobox-items", defaultItems: items, value: selectedItem, onChange: setSelectedItem, width: ComboBox.WIDTH.FLEX, disabled: fetchingItems }, (item) => (React.createElement(ComboBox.Item, { key: item.ITEMID, textValue: item.NAME },
            React.createElement(Text, null, item.NAME)))),
        failed && React.createElement(Text, { color: 'error' }, "Something has gone wrong, try again or contact support."),
        React.createElement(FlexColumn, { gap: 'xs' },
            React.createElement(SearchField, { isExpandable: false, onChange: onFilterChange, width: "600px" })),
        React.createElement(ListItem, null,
            React.createElement(FlexRow, { gap: 'xl' },
                React.createElement(Checkbox, { onChange: selectAllClick, checked: selectAll }),
                React.createElement(SubHeading, { size: '2' }, 'Select all'))),
        React.createElement(ListWrapper, null,
            React.createElement(Scrollbar, { maxHeight: scrollSectionHeight },
                React.createElement(FlexColumn, { alignItems: "flex-start", justifyContent: "flex-start" }, filteredTasks &&
                    filteredTasks.map(task => {
                        return (React.createElement(ListItem, { key: 'task-' + task.id, hasBorderBottom: true },
                            React.createElement(FlexRow, { gap: 'xl' },
                                React.createElement(Checkbox, { onChange: () => taskClick(task.id), checked: selectedTasks.includes(task.id) }),
                                React.createElement(Text, { size: '2' }, task.name))));
                    })))),
        React.createElement(HorizontalBar, null)));
};
const sageIntacctExportTasksModalQuery = graphql `
	query SageIntacctExportTasksModal_Query($projectId: ID!) {
		viewer {
			actualPersonId
			component(name: "sage_intacct_export_tasks_modal")
			...SageIntacctExportTasksModal_viewer @arguments(projectId: $projectId)
		}
	}
`;
export { sageIntacctExportTasksModalQuery };
export default createFragmentContainer(SageIntacctExportTasksModal, {
    viewer: graphql `
		fragment SageIntacctExportTasksModal_viewer on Viewer @argumentDefinitions(projectId: {type: "ID!"}) {
			project(internalId: $projectId) {
				id
				name
				sageProject {
					sageProjectId
				}
				tasks(first: 10000) @connection(key: "Project_tasks") {
					edges {
						node {
							id
							name
							sageIntacctId
						}
					}
				}
			}
		}
	`,
});
