import 'whatwg-fetch'; //IE 11 fetch polyfill
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {FormattedHTMLMessage, FormattedMessage} from 'react-intl';
import {Card} from '@forecast-it/design-system';
import Util from '../../../forecast-app/shared/util/util';
import {BUTTON_COLOR, BUTTON_STYLE, PERIOD_BUDGET_TYPE} from '../../../constants';
import SelectInput from '../../../components/inputs/select_input';
import Info from '../../../components/info';
import UploadingOverlay from '../../../forecast-app/shared/components/uploading-overlay/uploading_overlay';
import {MODAL_TYPE, showModal} from '../../../forecast-app/shared/components/modals/generic_modal_conductor';

import UpdateProjectMutation from '../../../mutations/update_project_mutation.modern';

import Warning from '../../../components/warning';
import Button from '../../../forecast-app/shared/components/buttons/button/button';
import jiraUtil from '../../../forecast-app/shared/util/jira_util';
import {AdoIcon, GithubIcon, HubspotIcon, JiraIcon, PipedriveIcon, SalesforceIcon, SlackIcon} from 'web-components';
import sageIntacctLogo from '../../../images/integrations/sage-intacct/sage-logo.png';
import {createToast} from '../../../forecast-app/shared/components/toasts/toast';

import ProjectIntegrationSection from '../../../components/project-settings/project_integration_section';
import SalesforceIntegrationSection from '../../../components/project-settings/salesforce_integration_section';
import GithubIntegrationSection from '../../../components/project-settings/github_integration_section';
import AzureDevOpsIntegrationSection from '../../../components/project-settings/AzureDevOpsIntegrationSection';
import JiraIntegrationSection from '../../../components/project-settings/JiraIntegrationSection';
import SlackIntegrationSection from '../../../components/project-settings/SlackIntegrationSection';

import LinkSalesforceOpportunityMutation from '../../../mutations/LinkSalesforceOpportunityMutation';
import UnlinkSalesforceOpportunityMutation from '../../../mutations/UnlinkSalesforceOpportunityMutation';
import LinkHubspotDealMutation from '../../../mutations/LinkHubspotDealMutation';
import UnlinkHubspotDealMutation from '../../../mutations/UnlinkHubspotDealMutation';
import LinkPipedriveDealMutation from '../../../mutations/LinkPipedriveDealMutation';
import UnlinkPipedriveDealMutation from '../../../mutations/UnlinkPipedriveDealMutation';
import SyncGitlabDataMutation from '../../../mutations/sync_gitlab_data_mutation';
import SetGitHubSyncTargetMutation from '../../../mutations/set_github_sync_target_mutation';

import {hasPermission} from '../../../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../Permissions';
import DirectApi, {projectUrl} from '../../../directApi';
import ProjectUtil from '../../../forecast-app/shared/util/project_util';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';
import {getProjectIndicatorString} from '../../../forecast-app/shared/components/project-indicator/support/ProjectIndicatorLogic';
import {getTaskUrl, pathIncludesTask} from '../../../forecast-app/shared/util/UrlUtil';

class ProjectSettingsIntegrations extends Component {
	constructor(props) {
		super(props);
		if (!props.viewer.project) {
			props.history.replace('/not-found');
			return;
		}
		this.state = {
			projectName: props.viewer.project.name ? props.viewer.project.name : '',
			sprintLengthInDays: props.viewer.project.sprintLengthInDays ? props.viewer.project.sprintLengthInDays : '',
			newStatusInputField: '',
			gearMenuExpanded: false,
			canDragStatus: true,
			harvestShowInactive: false,
			selectedLabels: props.viewer.project.projectLabels.edges.map(projectLabel => projectLabel.node.label.id),
			showOverlay: false,
			gitlabProjects: [],
			gitlabServerProjects: [],
			integrationLinking: false,
			harvestProjects: [],
			harvestTasks: [],
			excludedHarvestTasks: [],
			slackChannels: [],
			gitlabSelectedId: props.viewer.project.gitlabProjectId,
			gitlabServerSelectedId: props.viewer.project.gitlabServerProjectId,
			salesforceOpportunities: [],
			hubspotDeals: [],
			pipedriveDeals: [],
			fetchingHubspotDeals: true,
			fetchingPipedriveDeals: true,
			fetchingSalesforceOpportunities: true,
			githubRepositories: [],
			fetchingGithubRepositories: true,
			projectNameFocus: false,
			syncGitHubIssuesRepo: props.viewer.project.gitHubSyncTarget,
			unassignedTaskHourlyCost: props.viewer.project.unassignedTaskHourlyCost,
			defaultPeriodPeriodicity: props.viewer.project.defaultPeriodPeriodicity,
			defaultPeriodLength: props.viewer.project.defaultPeriodLength,
			defaultPeriodAmount:
				props.viewer.project.defaultPeriodBudgetType === PERIOD_BUDGET_TYPE.FIXED_HOURS
					? props.viewer.project.defaultPeriodHoursAmount
					: props.viewer.project.defaultPeriodPriceAmount,
		};

		this.salesforceFetchTimeout = null;
	}

	UNSAFE_componentWillMount() {
		const path = this.props.history.location.pathname;
		if (!Util.AuthorizeViewerAccess('project-settings')) {
			if (pathIncludesTask(path)) {
				this.props.history.push(
					projectUrl(this.props.viewer.project.companyProjectId, this.props.viewer.project.customProjectId) +
						'/workflow' +
						getTaskUrl(path)
				);
			} else {
				// if user doesnt have access rights to view this page redirect to no access page
				this.props.history.push('/not-authorized');
				Util.localStorageSetItem('project-section-last-viewed', 'workflow');
			}
		}
	}

	componentDidMount() {
		if (!this.props.viewer.project) return;

		const name =
			this.props.viewer.project.name !== null && this.props.viewer.project.name !== ''
				? this.props.viewer.project.name
				: getProjectIndicatorString(
						this.props.viewer.project.companyProjectId,
						this.props.viewer.project.customProjectId
				  );
		document.title = 'Settings - ' + name + ' - Forecast';

		if (this.props.viewer.company.gitlabEnabled) {
			DirectApi.Fetch('gitlab_projects').then(json => {
				if (this.props.viewer.project.gitlabProjectId) {
					json.push({
						id: this.props.viewer.project.gitlabProjectId,
						name: this.props.viewer.project.gitlabProjectName,
					});
				}
				this.setState({
					gitlabProjects: json,
				});
			});
		}

		if (this.props.viewer.company.gitlabServerEnabled) {
			DirectApi.Fetch('gitlab_server_projects').then(json => {
				if (this.props.viewer.project.gitlabServerProjectId) {
					json.push({
						id: this.props.viewer.project.gitlabServerProjectId,
						name: this.props.viewer.project.gitlabServerProjectName,
					});
				}
				this.setState({
					gitlabServerProjects: json,
				});
			});
		}

		if (this.props.viewer.company.harvestEnabled) {
			try {
				DirectApi.Fetch('harvest/projects').then(json => {
					this.setState({
						harvestProjects: json,
					});
				});
			} catch (e) {}
			try {
				DirectApi.Fetch('harvest/tasks').then(json => {
					this.setState({
						harvestTasks: json,
					});
				});
			} catch (e) {}
			try {
				DirectApi.Fetch(`harvest/excluded_tasks/${this.props.viewer.project.id}`).then(json => {
					this.setState({
						excludedHarvestTasks: json,
					});
				});
			} catch (e) {}
		}

		if (this.props.viewer.company.slackEnabled) {
			DirectApi.Fetch('slack/channels').then(json => {
				this.setState({
					slackChannels: json,
				});
			});
		}

		if (this.props.viewer.company.newSalesforceEnabled) this.getSalesforceOpportunities();

		if (this.props.viewer.company.hubspotAccountId) {
			DirectApi.Fetch(`hubspot/unlinkedDeals/${Util.getIdFromBase64String(this.props.viewer.project.id)}`).then(json => {
				this.setState({
					hubspotDeals: json.list,
					fetchingHubspotDeals: false,
				});
			});
		}

		if (this.props.viewer.company.pipedriveCompanyDomain) {
			DirectApi.Fetch(`pipedrive/unlinkedDeals/${Util.getIdFromBase64String(this.props.viewer.project.id)}`).then(
				json => {
					this.setState({
						pipedriveDeals: json.list,
						fetchingPipedriveDeals: false,
					});
				}
			);
		}

		if (this.props.viewer.company.githubEnabled) {
			DirectApi.Fetch('github_repositories_v2').then(json => {
				this.setState({
					githubRepositories: json,
					fetchingGithubRepositories: false,
				});
			});
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (!nextProps.viewer.project) {
			return;
		}
		if (nextProps.viewer.project.name !== this.state.projectName && !this.state.projectNameFocus) {
			this.setState({projectName: nextProps.viewer.project.name});
		}

		if (!nextProps.viewer.project.projectLabels) {
			// This is here to not fail when deleting a project (projectLabels should always be a list)
			return;
		}
		this.setState({
			selectedLabels: nextProps.viewer.project.projectLabels.edges.map(projectLabel => projectLabel.node.label.id),
			sprintLengthInDays: nextProps.viewer.project.sprintLengthInDays ? nextProps.viewer.project.sprintLengthInDays : '',
			defaultPeriodPeriodicity: nextProps.viewer.project.defaultPeriodPeriodicity,
			defaultPeriodLength: nextProps.viewer.project.defaultPeriodLength,
			defaultPeriodAmount:
				nextProps.viewer.project.defaultPeriodBudgetType === PERIOD_BUDGET_TYPE.FIXED_HOURS
					? nextProps.viewer.project.defaultPeriodHoursAmount || 0
					: nextProps.viewer.project.defaultPeriodPriceAmount || 0,
		});
	}

	getSalesforceOpportunities(searchString) {
		if (this.props.viewer.company.newSalesforceEnabled) {
			const url = `salesforce/unlinked_opportunities/${Util.getIdFromBase64String(this.props.viewer.project.id)}${
				searchString ? `?search=${searchString}` : ''
			}`;
			DirectApi.Fetch(url).then(json => {
				this.setState({
					salesforceOpportunities: json.list,
					fetchingSalesforceOpportunities: false,
				});
			});
		}
	}

	getSalesforceOpportunitiesWithTimeout(searchString) {
		clearTimeout(this.salesforceFetchTimeout);
		this.salesforceFetchTimeout = setTimeout(() => this.getSalesforceOpportunities(searchString), 1000);
	}

	handleSelectHarvestProject(selected) {
		const {formatMessage} = this.props.intl;

		const handleUnlinkHarvestProject = () => {
			Util.CommitMutation(UpdateProjectMutation, {
				project: this.props.viewer.project,
				newHarvestProjectId: '',
			});
		};

		if (this.props.viewer.project.harvestProject) {
			showModal({
				type: MODAL_TYPE.WARNING,
				warningMessageId: 'warning-modal.edit-warning-title',
				warningInformation: [
					'This action cannot be undone.',
					'Your Harvest project will be unlinked - time registrations will stay in Forecast, but no longer be linked to any time registrations in Harvest.',
				],
				buttons: [
					{
						text: formatMessage({id: 'common.cancel'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.WHITE,
					},
					{
						text: 'Unlink project',
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.RED,
						callback: handleUnlinkHarvestProject,
					},
				],
			});
		} else if (selected) {
			Util.CommitMutation(UpdateProjectMutation, {
				project: this.props.viewer.project,
				newHarvestProjectId: selected.value + '',
			});
		}
	}

	handleSelectHarvestTask(selected) {
		const values = selected.map(s => s.value);
		Util.CommitMutation(
			UpdateProjectMutation,
			{
				project: this.props.viewer.project,
				newExcludedHarvestTasks: values,
			},
			() =>
				this.setState({
					excludedHarvestTasks: this.state.harvestTasks.filter(t => values.includes(t.id)),
				})
		);
	}

	handleUnlinkFromJira() {
		jiraUtil.unlinkProject(this.props.viewer.project);
	}

	handleHarvestShowInactive(e) {
		this.setState({harvestShowInactive: e.target.checked});
	}

	handleSelectSlackChannel(selected) {
		Util.CommitMutation(UpdateProjectMutation, {
			project: this.props.viewer.project,
			slackChannelId: selected ? selected.value : '',
		});
	}

	handleSelectGitlabProject(selected) {
		this.setState({gitlabSelectedId: selected ? selected.value : null});
	}

	handleSelectGitlabServerProject(selected) {
		this.setState({gitlabServerSelectedId: selected ? selected.value : null});
	}

	syncJiraProject(jiraType, syncType) {
		showModal({
			type: MODAL_TYPE.SYNC_JIRA_PROJECT,
			jiraType,
			syncType,
			forecastProject: this.props.viewer.project,
		});
	}

	syncToGitlabProject() {
		const onSuccess = () => {
			this.setState({showOverlay: false});
			showModal({
				type: MODAL_TYPE.WARNING,
				warningMessageId: 'settings.gitlab.synchronization_in_progress',
				warningInformation: [<FormattedHTMLMessage id="settings.gitlab.synchronization_description_html" />],
				useInfoIcon: true,
				buttons: [
					{
						text: this.props.intl.formatMessage({id: 'common.ok'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.WHITE,
					},
				],
			});
		};
		this.setState({showOverlay: true});
		Util.CommitMutation(
			SyncGitlabDataMutation,
			{
				projectId: this.props.viewer.project.id,
				gitlabProjectId: this.state.gitlabSelectedId,
				gitlabProjectName: this.state.gitlabProjects.find(g => g.id.toString() === this.state.gitlabSelectedId).name,
				server: false,
			},
			onSuccess
		);
	}

	syncToGitlabServerProject() {
		const onSuccess = () => {
			this.setState({showOverlay: false});
			showModal({
				type: MODAL_TYPE.WARNING,
				warningMessageId: 'settings.gitlab.synchronization_in_progress',
				warningInformation: [<FormattedHTMLMessage id="settings.gitlab.synchronization_description_html" />],
				useInfoIcon: true,
				buttons: [
					{
						text: this.props.intl.formatMessage({id: 'common.ok'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.WHITE,
					},
				],
			});
		};
		this.setState({showOverlay: true});
		Util.CommitMutation(
			SyncGitlabDataMutation,
			{
				projectId: this.props.viewer.project.id,
				gitlabProjectId: this.state.gitlabServerSelectedId,
				gitlabProjectName: this.state.gitlabServerProjects.find(
					g => g.id.toString() === this.state.gitlabServerSelectedId
				).name,
				server: true,
			},
			onSuccess
		);
	}

	viewGitlabProject() {
		window.open('https://gitlab.com/' + this.props.viewer.project.gitlabProjectName + '/issues');
	}

	viewGitlabServerProject() {
		const url = this.props.viewer.company.gitlabServerUrl;
		window.open(url + '/' + this.props.viewer.project.gitlabServerProjectName + '/issues');
	}

	openLinkUnit4ProjectModal() {
		showModal({
			type: MODAL_TYPE.LINK_UNIT4_PROJECT,
			projectId: this.props.viewer.project.id,
		});
	}

	unlinkUnit4Project() {
		const {formatMessage} = this.props.intl;

		showModal({
			type: MODAL_TYPE.GENERIC,
			className: 'default-warning-modal',
			content: <Warning messageId="settings.unit4.unlink_project" />,
			buttons: [
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: formatMessage({id: 'common.unlink'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.RED,
					callback: () => {
						Util.CommitMutation(UpdateProjectMutation, {
							id: this.props.viewer.project.id,
							project: this.props.viewer.project,
							unit4Project: '',
						});
					},
				},
			],
		});
	}

	syncUnit4Project() {
		const {formatMessage} = this.props.intl;

		const callback = () => {
			DirectApi.Fetch(`unit4_sync_project/${this.props.viewer.project.id}`).then(json => {
				// Display success / failure
				showModal({
					type: MODAL_TYPE.GENERIC,
					headerText: json.success ? (
						<FormattedMessage id="settings.unit4.sync_project.success.header" />
					) : (
						<FormattedMessage id="settings.unit4.sync_project.failure.header" />
					),
					content: json.success ? (
						<FormattedMessage id="settings.unit4.sync_project.success.description" />
					) : (
						<FormattedMessage id="settings.unit4.sync_project.failure.description" />
					),
					buttons: [
						{
							text: formatMessage({id: 'common.ok'}),
							style: BUTTON_STYLE.FILLED,
							color: BUTTON_COLOR.GREEN,
						},
					],
				});
			});
		};

		showModal({
			type: MODAL_TYPE.GENERIC,
			headerText: <FormattedMessage id="settings.unit4.sync_project.modal.header" />,
			content: <FormattedMessage id="settings.unit4.sync_project.modal.description" />,
			buttons: [
				{
					text: formatMessage({id: 'common.cancel'}),
					callback: this.props.closeModal,
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: formatMessage({id: 'common.synchronize'}),
					callback,
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.GREEN,
				},
			],
		});
	}

	handleSelectGithubRepoToAdd(value) {
		Util.CommitMutation(UpdateProjectMutation, {
			project: this.props.viewer.project,
			githubRepositoryIds: value.map(val => parseInt(val.value)),
		});
	}

	saveRepoSyncTarget(target) {
		Util.CommitMutation(
			SetGitHubSyncTargetMutation,
			{
				projectId: this.props.viewer.project.id,
				target: target ? target.toString() : null,
			},
			() =>
				this.setState({
					syncGitHubIssuesRepo: target,
				})
		);
	}

	setSelectedIssuesSyncTarget(val) {
		const {formatMessage} = this.props.intl;
		showModal({
			type: MODAL_TYPE.GENERIC,
			content: (
				<div className="default-warning-modal">
					<Warning messageId="github.confirm_sync_change" useInfoIcon={true} />
					<div className="warning-part-2">
						<FormattedHTMLMessage
							id={val === null ? 'github.confirm_sync_remove_desc' : 'github.confirm_sync_change_desc'}
						/>
					</div>
				</div>
			),
			buttons: [
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: formatMessage({id: 'common.ok'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.GREEN,
					callback: () => this.saveRepoSyncTarget(val),
				},
			],
		});
	}

	selectSalesforceOpportunity(v) {
		Util.CommitMutation(
			LinkSalesforceOpportunityMutation,
			{
				projectId: this.props.viewer.project.id,
				opportunityId: v[0],
			},
			() =>
				createToast({
					duration: 5000,
					message: this.props.intl.formatMessage({
						id: 'settings.salesforce.project_linked_to_opportunity',
					}),
				})
		);
	}

	selectHubspotDeal(v) {
		Util.CommitMutation(
			LinkHubspotDealMutation,
			{
				projectId: this.props.viewer.project.id,
				dealId: v[0],
			},
			() =>
				createToast({
					duration: 5000,
					message: this.props.intl.formatMessage({
						id: 'settings.hubspot.project_linked_to_deal',
					}),
				})
		);
	}

	selectPipedriveDeal(v) {
		Util.CommitMutation(
			LinkPipedriveDealMutation,
			{
				projectId: this.props.viewer.project.id,
				dealId: v[0].toString(),
			},
			() =>
				createToast({
					duration: 5000,
					message: this.props.intl.formatMessage({
						id: 'settings.pipedrive.project_linked_to_deal',
					}),
				})
		);
	}

	unlinkSalesforceOpportunity() {
		Util.CommitMutation(
			UnlinkSalesforceOpportunityMutation,
			{projectId: this.props.viewer.project.id, opportunityId: this.props.viewer.project.salesforceOpportunity},
			() =>
				createToast({
					duration: 5000,
					message: this.props.intl.formatMessage({
						id: 'settings.salesforce.project_unlinked_from_opportunity',
					}),
				})
		);
	}

	unlinkHubspotDeal() {
		Util.CommitMutation(
			UnlinkHubspotDealMutation,
			{projectId: this.props.viewer.project.id, dealId: this.props.viewer.project.hubspotDeal},
			() =>
				createToast({
					duration: 5000,
					message: this.props.intl.formatMessage({
						id: 'settings.hubspot.project_unlinked_from_deal',
					}),
				})
		);
	}

	unlinkPipedriveDeal() {
		Util.CommitMutation(
			UnlinkPipedriveDealMutation,
			{projectId: this.props.viewer.project.id, dealId: this.props.viewer.project.pipedriveDeal},
			() =>
				createToast({
					duration: 5000,
					message: this.props.intl.formatMessage({
						id: 'settings.pipedrive.project_unlinked_from_deal',
					}),
				})
		);
	}

	render() {
		const {formatMessage} = this.props.intl;
		const {viewer} = this.props;
		const project = this.props.viewer.project;
		if (!project) return <div />;
		if (!project.projectPersons) {
			// This is here to not fail when deleting a project (projectPersons should always be a list)
			return <div />;
		}

		const hideTimeRegistrations = ProjectUtil.projectUsesManualProgress(project);

		const projectLocked = this.props.viewer.project.status === 'DONE' || this.props.viewer.project.status === 'HALTED';

		let formattedHarvestOptions = [];
		if (viewer.company.harvestEnabled && this.state.harvestProjects) {
			const projectId = parseInt(atob(this.props.viewer.project.id).replace('ProjectType:', ''));

			const harvestProjectOptions = this.state.harvestProjects.filter(
				p =>
					((this.state.harvestShowInactive || p.active) && p.projectId === null) ||
					p.projectId === projectId ||
					(viewer.project.harvestProjectId && p.id === viewer.project.harvestProjectId)
			);

			formattedHarvestOptions = harvestProjectOptions.map(option => ({value: option.id + '', label: option.name}));
		}

		let formattedHarvestTaskOptions = [];
		if (viewer.company.harvestEnabled && this.state.harvestTasks) {
			const harvestTaskOptions = this.state.harvestTasks;
			formattedHarvestTaskOptions = harvestTaskOptions.map(option => ({value: option.id, label: option.name}));
		}

		let formattedExcludedHarvestTasks = [];
		if (viewer.company.harvestEnabled && this.state.excludedHarvestTasks != null) {
			formattedExcludedHarvestTasks = this.state.excludedHarvestTasks.map(option => ({
				value: option.id,
				label: option.name,
			}));
		}

		let formattedSlackOptions = null;
		if (viewer.company.slackEnabled && this.state.slackChannels !== null) {
			formattedSlackOptions = this.state.slackChannels.map(option => ({value: option.id, label: `#${option.name}`}));
		}

		let formattedGithubOptions = null;
		let projectRepositories = [];

		if (viewer.company.githubEnabled && this.state.githubRepositories) {
			formattedGithubOptions = this.state.githubRepositories.map(p => ({
				value: p.id,
				label: p.name,
			}));
			projectRepositories = formattedGithubOptions.filter(option =>
				viewer.project.githubRepositoryIds.includes(option.value)
			);
		}

		// Salesforce linked Opp
		let salesforceLinkedOpp = null;
		if (
			this.state.salesforceOpportunities &&
			this.state.salesforceOpportunities.length > 0 &&
			this.props.viewer.project.salesforceOpportunity
		) {
			const link = this.state.salesforceOpportunities.find(
				opp => opp.Id === this.props.viewer.project.salesforceOpportunity
			);
			salesforceLinkedOpp = link ? link.Name : null;
		}

		let hubspotLinkedDeal = null;
		if (this.state.hubspotDeals && this.state.hubspotDeals.length > 0 && this.props.viewer.project.hubspotDeal) {
			const link = this.state.hubspotDeals.find(deal => deal.id === this.props.viewer.project.hubspotDeal);
			hubspotLinkedDeal = link ? link.properties.dealname : null;
		}

		let pipedriveLinkedDeal = null;
		if (this.state.pipedriveDeals && this.state.pipedriveDeals.length > 0 && this.props.viewer.project.pipedriveDeal) {
			const link = this.state.pipedriveDeals.find(deal => deal.id.toString() === this.props.viewer.project.pipedriveDeal);
			pipedriveLinkedDeal = link ? link.title : null;
		}

		let harvestLinkedProject = null;
		if (formattedHarvestOptions && formattedHarvestOptions.length > 0 && viewer.project.harvestProjectId) {
			harvestLinkedProject = formattedHarvestOptions.find(opt => opt.value === viewer.project.harvestProjectId);
		}

		return (
			<div className="section-content project-settings">
				{this.props.children}
				{this.state.showOverlay ? <UploadingOverlay /> : false}
				<div className="section-body">
					<div className="inner">
						<fieldset className="integrations">
							<div className="section-title-wrapper">
								<h3>
									<FormattedMessage id="settings.apps-integrations" />
								</h3>
								<Info
									infoMessage={formatMessage({
										id: 'settings.apps-integrations-description',
									})}
								/>
							</div>
							{!hideTimeRegistrations && viewer.company.harvestEnabled ? (
								<div>
									<div className="harvest-wrapper">
										<h3>
											<FormattedMessage id="title.app_harvest" />
										</h3>

										<SelectInput
											label={formatMessage({id: 'settings.harvest_project'})}
											locked={viewer.project.harvestProject || projectLocked}
											placeholder={formatMessage({
												id: 'settings.harvest_project_placeholder',
											})}
											id="harvest_project"
											options={formattedHarvestOptions}
											onChange={this.handleSelectHarvestProject.bind(this)}
											value={viewer.project.harvestProjectId ? viewer.project.harvestProjectId : null}
											clearable={true}
											multi={false}
											title={harvestLinkedProject ? harvestLinkedProject.label : ''}
										/>
										<div className="harvest-show-archived">
											<span>{formatMessage({id: 'harvest.show_archived'})}</span>
											<input
												disabled={projectLocked}
												checked={this.state.harvestShowInactive}
												onChange={this.handleHarvestShowInactive.bind(this)}
												id="harvest_show_archived"
												type="checkbox"
											/>
										</div>
										{viewer.project.harvestProject ? (
											<button
												className="remove-harvest-project-linking"
												onClick={this.handleSelectHarvestProject.bind(this)}
											>
												Unlink Harvest project
											</button>
										) : null}
									</div>
									<p className="exclude-description">
										<FormattedHTMLMessage id="settings.harvest_exclude_tasks_description" />
									</p>
									<SelectInput
										label={formatMessage({
											id: 'common.tasks',
										})}
										disabled={projectLocked}
										id="harvest_task"
										options={formattedHarvestTaskOptions}
										onChange={this.handleSelectHarvestTask.bind(this)}
										value={formattedExcludedHarvestTasks}
										clearable={true}
										multi={true}
									/>
								</div>
							) : null}

							{viewer.company.slackEnabled && formattedSlackOptions !== null ? (
								<div className="slack-wrapper">
									<h3>Slack</h3>
									<SelectInput
										label={formatMessage({id: 'settings.slack_channel'})}
										disabled={projectLocked}
										placeholder={formatMessage({
											id: 'settings.slack_channel_placeholder',
										})}
										id="slack_channel"
										options={formattedSlackOptions}
										onChange={this.handleSelectSlackChannel.bind(this)}
										value={
											viewer.project.slackChannel
												? atob(viewer.project.slackChannel.id).split(':')[1]
												: null
										}
										clearable={true}
										multi={false}
									/>
								</div>
							) : null}
							{viewer.company.jiraServerEnabled &&
							!this.props.viewer.project.isInProjectGroup &&
							!viewer.project.jiraCloudProject ? (
								<div className="jira-wrapper">
									<h3>JIRA Server</h3>
									<div className="sub-jira-wrapper">
										{viewer.project.jiraServerProject ? (
											<>
												<div className="jira-project-name">
													<FormattedMessage
														id="settings.jira.linked_to_project"
														values={{name: <b>{viewer.project.jiraServerProject.name}</b>}}
													/>
												</div>
												<Button
													text={formatMessage({id: 'settings.unlink_jira'})}
													onClick={this.handleUnlinkFromJira.bind(this)}
													buttonStyle={BUTTON_STYLE.OUTLINE}
													colorTheme={BUTTON_COLOR.GREY}
												/>
											</>
										) : (
											<Button
												text={'Sync to Jira Project'}
												onClick={this.syncJiraProject.bind(this, 'server', 'project')}
												buttonStyle={BUTTON_STYLE.OUTLINE}
												colorTheme={BUTTON_COLOR.GREEN}
											/>
										)}
									</div>
								</div>
							) : null}
							{viewer.company.gitlabEnabled ? (
								<div className="gitlab-wrapper">
									<h3>Gitlab</h3>
									<div>
										<FormattedMessage id="settings.gitlab.description" />
									</div>
									<div className="sub-gitlab-wrapper">
										<SelectInput
											label="Gitlab project"
											locked={projectLocked || viewer.project.gitlabProjectId !== null}
											placeholder="Select gitlab project"
											id="gitlab_project"
											options={this.state.gitlabProjects.map(p => ({
												value: p.id.toString(),
												label: p.name,
											}))}
											onChange={this.handleSelectGitlabProject.bind(this)}
											value={this.state.gitlabSelectedId}
											clearable={true}
											multi={false}
										/>
										{viewer.project.gitlabProjectId ? (
											<button
												className="gitlab-project-linking"
												onClick={this.viewGitlabProject.bind(this)}
											>
												{formatMessage({id: 'settings.view_gitlab_project'})}
											</button>
										) : (
											<button
												className="gitlab-project-linking"
												onClick={this.syncToGitlabProject.bind(this)}
												disabled={this.state.gitlabSelectedId == null}
											>
												{formatMessage({id: 'settings.sync_gitlab_project'})}
											</button>
										)}
									</div>
								</div>
							) : null}
							{viewer.company.gitlabServerEnabled ? (
								<div className="gitlab-wrapper">
									<h3>Gitlab server</h3>
									<div>
										<FormattedMessage id="settings.gitlab.description" />
									</div>
									<div className="sub-gitlab-wrapper">
										<SelectInput
											label="Gitlab server project"
											locked={projectLocked || viewer.project.gitlabServerProjectId !== null}
											placeholder="Select gitlab server project"
											id="gitlab_server_project"
											options={this.state.gitlabServerProjects.map(p => ({
												value: p.id.toString(),
												label: p.name,
											}))}
											onChange={this.handleSelectGitlabServerProject.bind(this)}
											value={this.state.gitlabServerSelectedId}
											clearable={true}
											multi={false}
										/>
										{viewer.project.gitlabServerProjectId ? (
											<button
												className="gitlab-project-linking"
												onClick={this.viewGitlabServerProject.bind(this)}
											>
												{formatMessage({id: 'settings.view_gitlab_project'})}
											</button>
										) : (
											<button
												className="gitlab-project-linking"
												onClick={this.syncToGitlabServerProject.bind(this)}
											>
												{formatMessage({id: 'settings.sync_gitlab_project'})}
											</button>
										)}
									</div>
								</div>
							) : null}

							{viewer.company.unit4Enabled ? (
								<div className="unit4-wrapper">
									<h3>Unit4</h3>
									<div className="unit4-description">
										<FormattedMessage id="settings.unit4.description" />
									</div>
									{viewer.project.unit4Project ? (
										<>
											<div className="unit4-description">
												<FormattedMessage
													id="settings.unit4.currently_linked"
													values={{
														unit4Project: (
															<b>
																{atob(viewer.project.unit4Project.id).replace(
																	'Unit4ProjectType:',
																	''
																) +
																	': ' +
																	viewer.project.unit4Project.name}
															</b>
														),
													}}
												/>
												<Button
													className="unit4-sync-button"
													text={formatMessage({id: 'common.synchronize'})}
													onClick={this.syncUnit4Project.bind(this)}
													buttonStyle={BUTTON_STYLE.OUTLINE}
													colorTheme={BUTTON_COLOR.GREY}
												/>
											</div>
											<Button
												text={formatMessage({id: 'common.unlink'})}
												onClick={this.unlinkUnit4Project.bind(this)}
												buttonStyle={BUTTON_STYLE.FILLED}
												colorTheme={BUTTON_COLOR.RED}
											/>
										</>
									) : (
										<Button
											text={formatMessage({id: 'settings.link_project'})}
											onClick={this.openLinkUnit4ProjectModal.bind(this)}
											buttonStyle={BUTTON_STYLE.OUTLINE}
											colorTheme={BUTTON_COLOR.GREY}
										/>
									)}
								</div>
							) : null}
							{hasPermission(PERMISSION_TYPE.MANAGE_ACCOUNT_SETTINGS) ? (
								<Link className="react-router-link" to={'/admin/integrations'}>
									<button className="app-catalogue-link-button">
										<FormattedMessage id="settings.apps-catalogue-link" />
									</button>
								</Link>
							) : null}
						</fieldset>
						{/* New integrations here */}
						{this.props.viewer.company.hubspotAccountId ? (
							<ProjectIntegrationSection
								icon={<HubspotIcon size={HubspotIcon.SIZE.BIG} />}
								title={'Hubspot'}
								description={
									<FormattedMessage
										id={'settings.integrations.deals.section_description'}
										values={{systemName: 'Hubspot'}}
									/>
								}
							>
								<SalesforceIntegrationSection
									key={'hubspotEnabled'}
									linkedValue={hubspotLinkedDeal}
									linkToValue={null}
									fetching={this.state.fetchingHubspotDeals}
									options={
										this.state.hubspotDeals
											? this.state.hubspotDeals.map(deal => ({
													value: deal.id,
													label: deal.properties.dealname,
											  }))
											: []
									}
									onSelect={v => this.selectHubspotDeal(v)}
									onUnlinkClick={() => this.unlinkHubspotDeal()}
									dropdownPlaceholder={this.props.intl.formatMessage({
										id: 'settings.hubspot.deal_dropdown_placeholder',
									})}
								/>
							</ProjectIntegrationSection>
						) : (
							''
						)}
						{this.props.viewer.company.pipedriveCompanyDomain ? (
							<ProjectIntegrationSection
								icon={<PipedriveIcon size={PipedriveIcon.SIZE.EXTRA_BIG} />}
								title={''}
								description={
									<FormattedMessage
										id={'settings.integrations.deals.section_description'}
										values={{systemName: 'Pipedrive'}}
									/>
								}
							>
								<SalesforceIntegrationSection
									key={'pipedriveCompanyDomain'}
									linkedValue={pipedriveLinkedDeal}
									linkToValue={null}
									fetching={this.state.fetchingPipedriveDeals}
									options={
										this.state.pipedriveDeals
											? this.state.pipedriveDeals.map(deal => ({value: deal.id, label: deal.title}))
											: []
									}
									onSelect={v => this.selectPipedriveDeal(v)}
									onUnlinkClick={() => this.unlinkPipedriveDeal()}
									dropdownPlaceholder={this.props.intl.formatMessage({
										id: 'settings.pipedrive.deal_dropdown_placeholder',
									})}
								/>
							</ProjectIntegrationSection>
						) : (
							''
						)}
						{this.props.viewer.company.newSalesforceEnabled ? (
							<ProjectIntegrationSection
								icon={<SalesforceIcon size={SalesforceIcon.SIZE.BIG} />}
								title={'Salesforce'}
								description={this.props.intl.formatMessage({id: 'settings.salesforce.section_description'})}
							>
								<SalesforceIntegrationSection
									key={'newSalesforceEnabled'}
									linkedValue={salesforceLinkedOpp}
									linkToValue={null}
									fetching={this.state.fetchingSalesforceOpportunities}
									options={
										this.state.salesforceOpportunities
											? this.state.salesforceOpportunities.map(opp => ({value: opp.Id, label: opp.Name}))
											: []
									}
									onSelect={v => this.selectSalesforceOpportunity(v)}
									onUnlinkClick={() => this.unlinkSalesforceOpportunity()}
									dropdownPlaceholder={this.props.intl.formatMessage({
										id: 'settings.salesforce.opportunity_dropdown_placeholder',
									})}
									onSearchStringChanged={searchString =>
										this.getSalesforceOpportunitiesWithTimeout(searchString)
									}
								/>
							</ProjectIntegrationSection>
						) : (
							''
						)}

						{this.props.viewer.company.githubEnabled ? (
							<ProjectIntegrationSection
								icon={<GithubIcon size={GithubIcon.SIZE.BIG} />}
								title={'Github'}
								description={this.props.intl.formatMessage({id: 'github.add_repository_label'})}
							>
								<GithubIntegrationSection
									key={'githubEnabled'}
									dropdownPlaceholder={this.props.intl.formatMessage({
										id: 'github.add_repository_placeholder',
									})}
									onSelect={v => this.handleSelectGithubRepoToAdd(v)}
									options={formattedGithubOptions}
									projectRepositories={projectRepositories}
									intl={this.props.intl}
									githubInstallationId={viewer.company.githubInstallationId}
									syncGitHubIssuesRepo={this.state.syncGitHubIssuesRepo}
									setSelectedIssuesSyncTarget={this.setSelectedIssuesSyncTarget.bind(this)}
									fetchingGithubRepositories={this.state.fetchingGithubRepositories}
								/>
							</ProjectIntegrationSection>
						) : null}

						{viewer.company.vstsEnabled ? (
							<ProjectIntegrationSection
								icon={<AdoIcon size={AdoIcon.SIZE.BIG} />}
								title={'Azure DevOps'}
								description={this.props.intl.formatMessage({id: 'settings.ado_description'})}
							>
								<AzureDevOpsIntegrationSection
									project={this.props.viewer.project}
									companyId={this.props.viewer.company.id}
								/>
							</ProjectIntegrationSection>
						) : (
							''
						)}

						{!this.props.viewer.project.isInProjectGroup && viewer.company.jiraCloudEnabled ? (
							<ProjectIntegrationSection
								icon={<JiraIcon size={JiraIcon.SIZE.BIG} />}
								title={'Jira'}
								description={
									this.props.viewer.project.isJiraProject
										? 'Change the settings of your Jira linked project here. These changes may take up to 24 hours to take effect.'
										: this.props.intl.formatMessage({id: 'settings.jira_description'})
								}
							>
								<JiraIntegrationSection project={this.props.viewer.project} />
							</ProjectIntegrationSection>
						) : (
							''
						)}

						{viewer.company.newSlackEnabled ? (
							<ProjectIntegrationSection
								icon={<SlackIcon size={SlackIcon.SIZE.BIG} />}
								title={'Slack'}
								description={this.props.intl.formatMessage({id: 'settings.slack.description'})}
							>
								<SlackIntegrationSection project={this.props.viewer.project} />
							</ProjectIntegrationSection>
						) : (
							''
						)}
						{hasFeatureFlag('sage_intacct_integration') &&
						viewer.company.sageIntacctConnectionEnabled &&
						!hasFeatureFlag('SAGE_INTACCT_RESTRICTED') ? (
							<Card src={sageIntacctLogo}>
								<Card.Title>Sage Intacct</Card.Title>
								<Card.Body>
									{viewer.project.sageProject.sageProjectId ? `Linked with Sage Intacct` : undefined}
								</Card.Body>
							</Card>
						) : (
							''
						)}
					</div>
				</div>
			</div>
		);
	}
}

export default ProjectSettingsIntegrations;
