const eventMap = new Map();
export const subscribe = (eventId, callback) => {
    if (!eventMap.has(eventId)) {
        eventMap.set(eventId, []);
    }
    eventMap.get(eventId).push(callback);
};
export const unsubscribe = (eventId, callback) => {
    if (eventMap.has(eventId)) {
        const handlerArray = eventMap.get(eventId);
        const callbackIndex = handlerArray.indexOf(callback);
        if (callbackIndex >= 0) {
            handlerArray.splice(callbackIndex, 1);
        }
        else {
            // eslint-disable-next-line no-console
            console.warn('Unsubscription unsuccessful - callback not found. ' + eventId);
        }
    }
    else {
        // eslint-disable-next-line no-console
        console.warn('Unsubscription unsuccessful - eventId not found. ' + eventId);
    }
};
export const dispatch = (eventId, ...args) => {
    if (!eventMap.has(eventId))
        return;
    eventMap.get(eventId).forEach(callback => callback.call(this, ...args));
};
export const EVENT_ID = {
    SCHEDULING_MODAL_MUTATION_SUCCESS: 0,
    WORKFLOW_MULTISELECT: 3,
    CANVAS_TIMELINE_FORCE_REDRAW: 4,
    SOCKET_NOTIFY: 5,
    TIME_REGISTRATION_MUTATION_SUCCESS: 6,
    NEW_TIME_REG_TASK: 7,
    SAVED_FILTERS_UPDATE: 9,
    SUBTASK_ESTIMATE_UPDATE: 9,
    SHOW_REMAINING_NOTICE_TASK_MODAL: 10,
    SUBTASK_MARKED_AS_DONE: 11,
    FILTERS_CHANGED: 12,
    SCROLLED: 13,
    SHOW_MODAL: 15,
    CLOSE_MODAL: 16,
    SHOW_LOADER: 17,
    HIDE_LOADER: 18,
    SHOW_OLD_TOAST: 19,
    RESET_SWITCH: 20,
    TIME_POPUP_OPENED: 21,
    RESET_INPUT: 22,
    SWITCH_EMPTY: 23,
    SHOW_CHANGES_SAVED: 24,
    TIMER_UPDATED: 25,
    MULTIPLE_PROJECTS_ADDED: 26,
    DEMO_CREATED: 27,
    SHOW_TOAST: 28,
    MUTATION_SENT: 30,
    CANVAS_TIMELINE_PRINT: 31,
    BUDGET_UPDATE_PROJECT: 32,
    SCHEDULING_RELOAD_DATA: 33,
    TOGGLE_SLIDEOUT: 34,
    CLOSE_SLIDEOUT: 35,
    SIDENAV_STATE_CHANGED: 36,
    POPUP_OPENED: 37,
    ZEN_MODE_TOGGLED: 38,
    SHOW_TIME_REG_SUGGESTIONS: 39,
    CLEAR_FILTERS: 40,
    CLEAR_SEARCH: 41,
    RETAINER_UPDATE_PROJECT: 42,
    CALENDAR_EVENTS_SHOW_MORE: 43,
    CALENDAR_EVENTS_SHOW_LESS: 44,
    TIMESHEET_PERSON_CHANGED: 45,
    TIMESHEET_SHOW_SIDE_MODAL: 46,
};
