import React, { useCallback, useMemo, useState } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { BulkSelector, ComboBox, FlexColumn, FlexRow, Label, Table, Text, useTableFunctions } from '@forecast-it/design-system';
import Styled from 'styled-components';
import { formatDate } from './SageSyncAnalysisPage';
import { fixIssues } from './sage-analysis-actions/FixIssues';
import { hideIssues } from './sage-analysis-actions/HideIssues';
export const ANALYSIS_COLUMNS = {
    ID: 'id',
    FORECAST_COMPANY_ID: 'forecastCompanyId',
    SAGE_ENTITY_RECORD_NUMBER: 'sageEntityRecordNumber',
    FORECAST_ENTITY_ID: 'forecastEntityId',
    ENTITY_TYPE: 'entityType',
    RELEVANCY: 'relevancy',
    COMPANY_ANALYSIS_ID: 'companyAnalysisId',
    DESCRIPTION: 'description',
};
const TableWrapper = Styled.div `
  padding: 64px;
  padding-top: 0;
  margin-top: 64px;
  height: 100%;
`;
const isSupported = (row, supportedIssueHandlers) => {
    for (let i = 0; i < supportedIssueHandlers.length; i++) {
        const handler = supportedIssueHandlers[i];
        if (handler.entityType === row.entityType && handler.relevancy === row.relevancy) {
            return true;
        }
    }
    return false;
};
function SageSyncAnalysisTable({ viewer, scrollRef }) {
    var _a;
    const companyAnalysis = (_a = viewer.missionControlData) === null || _a === void 0 ? void 0 : _a.companySyncAnalysis;
    const companyId = companyAnalysis === null || companyAnalysis === void 0 ? void 0 : companyAnalysis.companyId;
    const syncDateNumber = companyAnalysis === null || companyAnalysis === void 0 ? void 0 : companyAnalysis.syncDate;
    const syncDate = syncDateNumber ? new Date(syncDateNumber) : null;
    const syncDateString = syncDate ? formatDate(new Date(syncDate)) : null;
    const entries = (companyAnalysis === null || companyAnalysis === void 0 ? void 0 : companyAnalysis.entries) || [];
    const supportedIssueHandlers = (companyAnalysis === null || companyAnalysis === void 0 ? void 0 : companyAnalysis.supportedIssueHandlers) || [];
    const typeFilterOptions = entries
        .reduce((acc, { entityType }) => {
        if (!acc.includes(entityType)) {
            acc.push(entityType);
        }
        return acc;
    }, [])
        .map(entityType => ({ id: entityType }));
    const relevancyFilterOptions = entries
        .reduce((acc, { relevancy }) => {
        if (!acc.includes(relevancy)) {
            acc.push(relevancy);
        }
        return acc;
    }, [])
        .map(relevancy => ({ id: relevancy }));
    const [typeFilter, setTypeFilter] = useState(null);
    const [relevancyFilter, setRelevancyFilter] = useState(null);
    const filter = useMemo(() => {
        const result = [];
        if (typeFilter) {
            result.push(typeFilter);
        }
        if (relevancyFilter) {
            result.push(relevancyFilter);
        }
        return result.length > 0 ? result.join(' ') : '';
    }, [typeFilter, relevancyFilter]);
    const enableSelection = true;
    const [selectedRowIds, setSelectedRowIds] = useState([]);
    const onSelectionChange = useCallback((rows) => {
        setSelectedRowIds(rows.map(row => row.id));
    }, []);
    const [tableRef, table] = useTableFunctions();
    return (React.createElement(TableWrapper, null,
        React.createElement(FlexColumn, { alignItems: 'baseline' },
            React.createElement(FlexRow, null,
                React.createElement(Label, null, "Analysis Date"),
                React.createElement(Text, null, syncDateString)),
            React.createElement(FlexRow, null,
                React.createElement(ComboBox, { label: 'Type filter', value: typeFilter, onChange: setTypeFilter, items: typeFilterOptions }, (item) => (React.createElement(ComboBox.Item, { textValue: item.id },
                    React.createElement(Text, null, item.id)))),
                React.createElement(ComboBox, { label: 'Relevancy filter', value: relevancyFilter, onChange: setRelevancyFilter, items: relevancyFilterOptions }, (item) => (React.createElement(ComboBox.Item, { textValue: item.id },
                    React.createElement(Text, null, item.id)))))),
        React.createElement(Table, { "data-cy": 'sage-sync-analysis-table', data: entries, width: '100%', stickyHeader: true, tableRef: tableRef, getRowId: (row) => row.id, scrollRef: scrollRef, searchValue: filter, highlightSearch: true, enableRowSelection: row => isSupported(row.original, supportedIssueHandlers), onSelectionChange: enableSelection ? onSelectionChange : undefined },
            React.createElement(Table.TextColumn, { id: ANALYSIS_COLUMNS.ENTITY_TYPE, accessorKey: 'entityType', "data-cy": 'entry-entityType', header: 'Entity Type', allowSorting: true, ellipsis: true, width: '10%' }),
            React.createElement(Table.TextColumn, { id: ANALYSIS_COLUMNS.RELEVANCY, accessorKey: 'relevancy', "data-cy": 'entry-relevancy', header: 'Relevancy', allowSorting: true, ellipsis: true, width: '10%' }),
            React.createElement(Table.TextColumn, { id: ANALYSIS_COLUMNS.SAGE_ENTITY_RECORD_NUMBER, accessorKey: 'sageEntityRecordNumber', "data-cy": 'entry-sageEntityRecordNumber', header: 'Sage Entity Record Number', allowSorting: true, ellipsis: true, width: '10%' }),
            React.createElement(Table.TextColumn, { id: ANALYSIS_COLUMNS.FORECAST_ENTITY_ID, accessorKey: 'forecastEntityId', "data-cy": 'entry-forecastEntityId', header: 'Forecast Entity ID', allowSorting: true, ellipsis: true, width: '10%' }),
            React.createElement(Table.TextColumn, { id: ANALYSIS_COLUMNS.DESCRIPTION, accessorKey: 'description', "data-cy": 'entry-description', header: 'Description', allowSorting: true, ellipsis: true, width: '30%' })),
        React.createElement(BulkSelector, { label: 'Reports selected', onClear: table.clearSelections, show: selectedRowIds.length > 0, "data-cy": 'bulk-selector' },
            React.createElement(BulkSelector.MainAction, { "data-cy": 'bulk-fix-issues', onClick: () => fixIssues(companyId, selectedRowIds, () => {
                    table.clearSelections();
                }) }, "Fix issues"),
            React.createElement(BulkSelector.MainAction, { "data-cy": 'bulk-hide-issues', onClick: () => hideIssues(selectedRowIds, () => {
                    table.clearSelections();
                }) }, "Hide issues (TODO)"))));
}
const sageSyncAnalysisTableQuery = graphql `
	query SageSyncAnalysisTable_Query($companyId: Int, $analysisId: Int) {
		viewer {
			actualPersonId
			component(name: "sage_sync_analysis_table")
			...SageSyncAnalysisTable_viewer @arguments(companyId: $companyId, analysisId: $analysisId)
		}
	}
`;
export { sageSyncAnalysisTableQuery };
export default createFragmentContainer(SageSyncAnalysisTable, {
    viewer: graphql `
		fragment SageSyncAnalysisTable_viewer on Viewer
		@argumentDefinitions(companyId: {type: "Int"}, analysisId: {type: "Int"}) {
			id
			missionControlData {
				companySyncAnalysis(companyId: $companyId, analysisId: $analysisId) {
					id
					companyId
					syncDate
					entries {
						id
						companyAnalysisId
						forecastCompanyId
						sageEntityRecordNumber
						forecastEntityId
						entityType
						relevancy
						description
					}
					supportedIssueHandlers {
						entityType
						relevancy
					}
				}
			}
		}
	`,
});
