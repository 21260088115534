import Util from '../../../../forecast-app/shared/util/util';
import FixSageSyncAnalysisIssuesMutation from '../../../../mutations/mission_control/fix_sage_sync_analysis_issues_mutation';
export const fixIssues = (companyId, ids, successCallback) => {
    console.log('DEBUG fixIssues ids:' + ids);
    if (companyId) {
        Util.CommitMutation(FixSageSyncAnalysisIssuesMutation, {
            companyId: companyId,
            ids: ids,
        }, successCallback);
    }
};
