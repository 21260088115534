import React, {useEffect, useState} from 'react';
import {WarningIcon} from 'web-components/icons';
import {genKey} from 'draft-js';
import GenericModal from '../../../../modal/generic_modal';
import RichTextItem from '../../../../../components/richtext_item';
import {StatusIndicator} from '../../project_health_status/StatusIndicator';
import {ProjectStatusModalRow, ProjectStatusModalTitle, RichTextWrapper} from './ProjectStatusModal.styled';
import CreateProjectStatusMutation from '../../../../../mutations/create_project_status_mutation';
import {createToast} from '../../../../../forecast-app/shared/components/toasts/another-toast/toaster';
import Util from '../../../../../forecast-app/shared/util/util';
import * as tracking from '../../../../../tracking';
import {useIntl} from 'react-intl';
import {trackEvent} from '../../../../../tracking/amplitude/TrackingV2';
import {hasFeatureFlag} from '../../../../../forecast-app/shared/util/FeatureUtil';
import {useRichTextEditorFunctions} from '@forecast-it/design-system';
import {RichTextEditorDraftJsWrapper} from '../../../../../forecast-app/shared/components/rich-text-editor-draftjs-wrapper/RichTextEditorDraftJsWrapper';
import {convertFromHtmlToDraftJs} from '../../../../../forecast-app/shared/components/rich-text-editor-draftjs-wrapper/HtmlToDraftJsConverter';

const convertWarningVariantToStatusColor = warningVariant => {
	switch (warningVariant) {
		case WarningIcon.VARIANT.DANGER:
			return '#ff3300';
		case WarningIcon.VARIANT.WARNING:
			return '#ffc107';
		default:
			return '#33cc33';
	}
};

const mapInfoToDraftJSBlocks = infoText => {
	return [
		{key: genKey(), text: infoText + '.', type: 'unstyled', depth: 0, inlineStyleRanges: [], entityRanges: [], data: {}},
		{key: genKey(), text: '', type: 'unstyled', depth: 0, inlineStyleRanges: [], entityRanges: [], data: {}},
	];
};

const convertInfoTextToStatusDescription = activeInsightsInfoText => {
	if (!activeInsightsInfoText || activeInsightsInfoText.length === 0) return '';

	const activeInsightBlocks = activeInsightsInfoText.reduce((acc, info) => acc.concat(mapInfoToDraftJSBlocks(info)), []);

	return JSON.stringify({
		blocks: activeInsightBlocks,
		entityMap: {},
	});
};

export const ProjectStatusModalV2 = ({
	projectId,
	suggestedStatus,
	activeInsightsInfoText,
	triggeredByStatusDigest = false,
	closeModal,
}) => {
	const [selectedWarning, setSelectedWarning] = useState(suggestedStatus);
	const [description, setDescription] = useState(convertInfoTextToStatusDescription(activeInsightsInfoText));

	const intl = useIntl();
	useEffect(() => {
		tracking.trackPageAction('Project Status Modal shown', {
			projectId: projectId,
			suggestedStatus: suggestedStatus?.warningType,
			triggeredByStatusDigest: triggeredByStatusDigest,
		});
		trackEvent('Project Status Modal', 'Shown', {
			suggestedStatus: suggestedStatus?.warningType,
			triggeredByStatusDigest: triggeredByStatusDigest,
		});
	}, []);

	const {editorRef, getEditorValueAsHtml} = useRichTextEditorFunctions();

	const handleSave = () => {
		const color = convertWarningVariantToStatusColor(selectedWarning);
		const onSuccess = () => {
			if (suggestedStatus) {
				const suggestedColor = convertWarningVariantToStatusColor(suggestedStatus);
				tracking.trackAIEvent('Project status updated', 'suggested_status', null, {
					projectId: projectId,
					selectedColor: Util.getProjectStatusColorName(color),
					suggestedColor: Util.getProjectStatusColorName(suggestedColor),
					suggestionSelected: color === suggestedColor,
					triggeredByStatusDigest: triggeredByStatusDigest,
				});
				trackEvent('Suggested Status', 'Feedback Given', {
					selectedColor: Util.getProjectStatusColorName(color),
					suggestedColor: Util.getProjectStatusColorName(suggestedColor),
					suggestionSelected: color === suggestedColor,
					triggeredByStatusDigest: triggeredByStatusDigest,
				});
			}
			createToast({message: intl.formatMessage({id: 'project_status_modal.status_saved'}), duration: 3000});
			closeModal();
		};

		const draftJsContent = hasFeatureFlag('plate_editor') ? convertFromHtmlToDraftJs(getEditorValueAsHtml()) : description;

		Util.CommitMutation(
			CreateProjectStatusMutation,
			{
				projectId: projectId,
				color: color,
				description: draftJsContent,
			},
			onSuccess
		);
		setDescription('');
	};

	const getContent = () => {
		return (
			<>
				<ProjectStatusModalTitle>
					{`Select the project status. ${
						suggestedStatus ? 'The highlighted option is suggested based on the current state of the insights.' : ''
					}`}
				</ProjectStatusModalTitle>
				<ProjectStatusModalRow>
					<StatusIndicator
						warning={WarningIcon.VARIANT.NO_WARNING}
						active={selectedWarning === WarningIcon.VARIANT.NO_WARNING}
						onSelect={() => setSelectedWarning(WarningIcon.VARIANT.NO_WARNING)}
					/>
					<StatusIndicator
						warning={WarningIcon.VARIANT.WARNING}
						active={selectedWarning === WarningIcon.VARIANT.WARNING}
						onSelect={() => setSelectedWarning(WarningIcon.VARIANT.WARNING)}
					/>
					<StatusIndicator
						warning={WarningIcon.VARIANT.DANGER}
						active={selectedWarning === WarningIcon.VARIANT.DANGER}
						onSelect={() => setSelectedWarning(WarningIcon.VARIANT.DANGER)}
					/>
				</ProjectStatusModalRow>
				<ProjectStatusModalTitle>Description</ProjectStatusModalTitle>

				<RichTextWrapper minHeight={90}>
					{hasFeatureFlag('plate_editor') ? (
						<RichTextEditorDraftJsWrapper
							initialDraftJsContent={description}
							onDraftJsChanged={setDescription}
							editorRef={editorRef}
							data-cy={'status-description'}
							placeholder={'Write down your throughts on the current status of the project...'}
						/>
					) : (
						<RichTextItem
							cy={'status-description'}
							projectLocked={false}
							alwaysShowControls={true}
							//characterLimit={this.props.viewer.company.characterLimit}
							text={description}
							handleTextChange={desc => setDescription(desc)}
							//mentions={mentions}
							//mentionsLabels={mentionsLabels}
							placeholder={'Write down your throughts on the current status of the project...'}
							hideEmojiPicker
						/>
					)}
				</RichTextWrapper>
			</>
		);
	};

	return (
		<GenericModal
			buttons={[
				{
					text: 'Update status',
					callback: () => handleSave(),
				},
			]}
			closeModal={closeModal}
			content={getContent()}
			headerText={'Update status'}
			modalWidth={900}
		/>
	);
};
